<template>
  <b-row>
    <b-col sm="12">
      <b-card>
        <b-row>
          <b-col sm="7">
            <h4 id="traffic" class="card-title mb-0">Ventas Provisorias</h4>
            <div class="small text-muted">Generación de comprobantes de venta sin aprobación</div>
          </b-col>
          
          <b-col sm="5">
            <b-col sm="12" class="d-none d-md-block" v-if="!table.mostrarFiltros">
              <b-button type="button" variant="outline-dark" size="sm" class="btn-pill float-right" @click="table.mostrarFiltros=true" v-b-tooltip.hover title="Mostrar filtros">
                <i class="fa fa-filter"></i> Mostrar Filtros
              </b-button>
            </b-col>
            <b-col sm="12" class="d-none d-md-block" v-else>
              <b-button type="button" variant="outline-dark" size="sm" class="btn-pill float-right" v-if="table.mostrarFiltros" @click="table.mostrarFiltros=false" v-b-tooltip.hover title="Ocultar filtros">
                <i class="fa fa-window-close"></i>
              </b-button>
            </b-col>
          </b-col>                    
        </b-row>
      </b-card>

      <b-card id="list-sales" v-if="table.mostrarFiltros">
        <b-row >
          <b-col sm="10">            
            <b-row>            
              <b-col sm="12" class="pb-1">
                <b-row>         
                  <b-col md="3" class="p-1 pb-1">
                    <b-form-input type="date" size="md" v-model="filters.date_start" placeholder="Fecha Desde"></b-form-input>
                  </b-col>
                  <b-col md="3" class="p-1 pb-1">
                    <b-form-input type="date" size="md" v-model="filters.date_end" placeholder="Fecha Hasta"></b-form-input>
                  </b-col> 
                  <b-col sm="3" class="p-1 pb-1">
                    <v-select :options="arr.filters.status_imputation" v-model="filters.status_imputation" placeholder="Estado" :multiple="false" :select-on-tab="true"></v-select>
                  </b-col>  
                </b-row>
              </b-col>         
              <b-col sm="12">
                <b-row>
                  <b-col sm="3" v-if="moduleSellersActive" class="p-1">
                    <v-select :options="arr.filters.sellers" v-model="filters.sellers" placeholder="Vendedores" :multiple="true" :select-on-tab="true"></v-select>
                  </b-col>                                    
                  <b-col sm="3" class="p-1">
                    <v-select :options="arr.filters.customers" v-model="filters.customers" placeholder="Clientes" :multiple="true" :select-on-tab="true"></v-select>
                  </b-col>  
                  <b-col sm="3" class="p-1">
                    <v-select :options="arr.filters.points_sales" v-model="filters.points_sales" placeholder="Punto de Venta" :multiple="false" :select-on-tab="true"></v-select>
                  </b-col> 
                </b-row>
              </b-col>
            </b-row>
          </b-col>
          <b-col sm="2">
            <b-row>
              <b-col sm="12">
                <b-button variant="outline-dark" @click="filterSales()" size="sm" class="pull-right">
                  <b-icon icon="filter" class="mr-1" style="font-size:15px;"></b-icon>              
                  Filtrar
                </b-button>
              </b-col>   
              <b-col sm="12">
                <b-link>
                  <export-excel
                    class = 'pull-right mt-2'
                    :data = "arr.export"
                    worksheet = "Listado de Ventas Provisorias"
                    name = "list-sales-provisory.xls">
                    Exportar Datos
                  </export-excel>            
                </b-link>
              </b-col>
            </b-row>
          </b-col>
        </b-row>              
      </b-card>

      <b-row>
        <b-col lg="8" class="pr-0" id="view_list_sales">
          <b-card>
            <b-row>
              <b-col md="5">
                <b-form-group>
                  <b-input-group>
                    <b-form-input type="number" 
                                  placeholder="Buscar por Nº de Comprobante de Venta" 
                                  v-model="filters.number"
                                  v-on:keyup.enter.native="filterSales()"
                                  size="sm">
                    </b-form-input>   
                    <b-input-group-append>
                      <b-button size="sm" variant="secondary" title="Filtrar" @click="filterSales()">
                        <b-icon icon="filter"></b-icon>
                      </b-button>
                    </b-input-group-append>                               
                  </b-input-group>
                </b-form-group>          
              </b-col>
              <b-col md="7">                
                <b-dropdown right text="Agregar" variant="primary" size="sm" class="btn-pill float-right ml-2" v-b-tooltip.hover title="Agregar Comprobante">
                  <b-dropdown-item @click="add('factura')"><b-icon icon="plus-circle"></b-icon> Factura</b-dropdown-item>
									<b-dropdown-item @click="add('notadebito')"><b-icon icon="plus-circle"></b-icon> Nota de Débito</b-dropdown-item>
									<b-dropdown-item @click="add('notacredito')"><b-icon icon="plus-circle"></b-icon> Nota de Crédito</b-dropdown-item>
                  <b-dropdown-divider></b-dropdown-divider>
                  <b-dropdown-item @click="add('notacredito', 'discounts')"><b-icon icon="plus-circle"></b-icon> Descuentos (NC)</b-dropdown-item>
                </b-dropdown>       								
              </b-col>
              <b-col sm="12">
                <b-table class="mb-0 table-sales-custom"
                        ref="table"
                        responsive
                        head-variant="dark"
                        :hover="true"
                        :small="true"
                        :fixed="true"
                        :items="table.items"
                        :fields="table.fields"
                        :filter="table.filter"
                        :current-page="table.currentPage"
                        :per-page="table.perPage"
                        selectable
                        select-mode="single"
                        @row-selected="onRowSelected"  
                        :busy="table.isBusy"                      
                        v-if="table.items.length || table.isBusy">                       
                    
                    <template v-slot:table-colgroup="scope">
                      <col
                        v-for="field in scope.fields"                    
                        :key="field.key"
                        :style="{ width: field.width }"
                      >
                    </template> 

                    <template v-slot:table-busy>
                      <div class="text-center text-danger my-2">
                        <b-spinner class="align-middle"></b-spinner>
                        <strong>Cargando...</strong>
                      </div>
                    </template>

                    <template v-slot:cell(id)="data">
                      <div v-if="data.item.type_voucher && data.item.point_sale">
                        {{data.item.type_voucher.name}} <br>
                        {{data.item.point_sale.point_sale.toString().padStart(4,'0') + '-' + data.item.number.toString().padStart(8,'0')}}
                      </div>
                    </template>
                    
                    <template v-slot:cell(date)="data">
                      {{moment(data.item.date).format('DD/MM/YYYY')}}
                    </template>

                    <template v-slot:cell(date_expiration)="data">
                      {{moment(data.item.date_expiration).format('DD/MM/YYYY')}}
                    </template>                    

                    <template v-slot:cell(sellers_id)="data">                      
                      <span v-if="data.item.sellers_id" v-b-tooltip.hover :title="'VENDEDOR: ' + data.item.seller.staff.name">
                        <b-avatar :src="data.item.seller.staff.image"
                                  v-if="data.item.seller.staff.image">
                        </b-avatar>
                        <b-avatar v-else                                                                         
                                  variant="dark">
                        </b-avatar>                             
                      </span>       
                    </template>

                    <template v-slot:cell(staff_id)="data">                      
                      <span v-if="data.item.staff_id" v-b-tooltip.hover :title="'STAFF: ' + data.item.staff.name"> 
                        <b-avatar :src="data.item.staff.image"
                                  v-if="data.item.staff.image">
                        </b-avatar>
                        <b-avatar v-else                                                                         
                                  variant="dark">
                        </b-avatar>              
                      </span>               
                    </template>
                    
                    <template v-slot:cell(customers_id)="data">  
                      <div v-if="data.item.customer">            
                        <b-avatar :src="data.item.customer.image"
                                  v-if="data.item.customer.image">
                        </b-avatar>
                        <b-avatar v-else 
                                  icon="building"                                            
                                  variant="dark">
                        </b-avatar>                                                     
                        &nbsp;<b>{{data.item.customer.name}}</b>   
                        
                        <b-icon icon="circle-fill" v-if="!data.item.customer.active" class="ml-2" variant="danger" v-b-tooltip.hover title="Cliente INACTIVO" />                                       
                      </div>
                    </template>

                    <template v-slot:cell(amount_total)="data">  
                      <div v-if="data.item.currency">
                        <div style="color:red;" v-if="data.item.type_voucher.type_balance == '-'">
                          <b>({{Intl.NumberFormat('es-AR',{style:'currency',currency: data.item.currency.code}).format(data.item.amount_total)}})</b>
                        </div>
                        <div style="color:green;" v-else>
                          <b>{{Intl.NumberFormat('es-AR',{style:'currency',currency: data.item.currency.code}).format(data.item.amount_total)}}</b>
                        </div>                        
                      </div>
                    </template>

                    <template v-slot:cell(amount_pending)="data">  
                      <div v-if="data.item.currency">
                        <div style="color:green;" v-if="data.item.type_voucher.type_balance == '+' && data.item.amount_total!=data.item.amount_impute">
                          <b>{{Intl.NumberFormat('es-AR',{style:'currency',currency: data.item.currency.code}).format(getPendingVoucherSale(data.item))}}</b>
                        </div>                                                               
                      </div>
                    </template>

                    <template v-slot:cell(observations)="data">
                      <div v-if="data.item.observations" v-b-tooltip.hover :title="data.item.observations">
                        <b-icon icon="chat-square-text"></b-icon>
                      </div>                                              
                    </template>
                    
                    <template v-slot:cell(f_action)="data">
                      <b-dropdown right 
                                  text="Acción" 
                                  size="sm" 
                                  variant="outline-dark" 
                                  class="pull-right" 
                                  @show="setConfigTableSalesShow"
                                  @hide="setConfigTableSalesHide"
                                  v-if="access.elements.approvalSalesTrashed || access.elements.disapproveSalesTrashed">
                        
                        <b-dropdown-item @click="approvalSales(data.item)" v-if="access.elements.approvalSalesTrashed">
                          <b-icon icon="check2-square"></b-icon> Aprobar
                        </b-dropdown-item>   
                        <b-dropdown-item @click="disapproveSales(data.item)" v-if="access.elements.disapproveSalesTrashed">
                          <b-icon icon="trash2"></b-icon> NO Aprobado
                        </b-dropdown-item> 

                      </b-dropdown>
                    </template>
                </b-table>
                <b-alert v-else variant="warning" show>No se encontraron registros</b-alert>
              </b-col>
            </b-row>
          </b-card>
        </b-col>
        <b-col lg="4" id="contain_details_items">
          <div id="details_items">
            <b-card>            
              <b-row>              
                <b-col md="12">
                  
                  <!-- COMPROBANTE SELECCIONADO -->
                  <b-row class="mb-3">
                    <b-col md="8">
                      <div class="crud-sales-title" v-if="itemSelected">
                        <div class="crud-sales-title">
                          <span class="crud-sales-code" v-if="itemSelected.type_voucher && itemSelected.point_sale">                          
                            {{itemSelected.type_voucher.name}} {{itemSelected.point_sale.point_sale.toString().padStart(4,'0') + '-' + itemSelected.number.toString().padStart(8,'0')}}                          
                          </span><br>
                          <span v-if="itemSelected.customer">
                            {{this.itemSelected.customer.name}}                          
                          </span>                          
                        </div>
                      </div>
                    </b-col>
                    <b-col md="4">
                      <b-row>
                        <b-col md="12">
                          <b-button type="button" variant="outline-dark" size="sm" class="btn-pill float-right" @click="hideSub()" v-b-tooltip.hover title="Ocultar Detalle">
                            <i class="fa fa-window-close"></i>
                          </b-button>
                        </b-col>                      
                      </b-row>                   
                    </b-col>
                  </b-row>

                  <!-- DETALLE -->                
                  <b-row>
                    <b-col md="12" v-if="itemSelected">     
                      <b-link v-if="tableSub.items.length" 
                              @click="openDetail()"
                              class="pull-right">
                        Ver detalle completo
                      </b-link>                                     
                      <b-table  class="mb-0 table-sales-sub"
                                responsive
                                head-variant="dark"
                                :hover="true"
                                :small="true"
                                :fixed="true"
                                :items="tableSub.items"
                                :fields="tableSub.fields"                            
                                v-if="tableSub.items.length">   
                        
                        <template v-slot:table-colgroup="scope">
                          <col
                            v-for="field in scope.fields"                    
                            :key="field.key"
                            :style="{ width: field.width }"
                          >
                        </template>                         
                        
                        <template v-slot:cell(quantity)="row"> 
                          {{row.item.quantity}}
                          <span v-if="row.item.unit_measurement">{{row.item.unit_measurement.reference}}</span>
                        </template>

                        <template v-slot:cell(product)="row">
                          <div v-html="getDetailName(row.item)"></div>
                        </template>

                      </b-table>                      
                      <b-alert v-else variant="warning" show>No se encontraron registros</b-alert>

                      <hr>
                      
                      <b-table  class="mt-2 table-sales-sub"
                                responsive
                                head-variant="dark"
                                :hover="true"
                                :small="true"
                                :fixed="true"
                                :items="tableImputation.items"
                                :fields="tableImputation.fields"                            
                                v-if="tableImputation.items.length">   
                        
                        <template v-slot:table-colgroup="scope">
                          <col
                            v-for="field in scope.fields"                    
                            :key="field.key"
                            :style="{ width: field.width }"
                          >
                        </template>                         
                        
                        <template v-slot:cell(date)="row"> 
                          {{moment(row.item.date).format('DD/MM/YYYY')}}
                        </template>              

                        <template v-slot:cell(reference)="row"> 
                          {{row.item.reference}}                          
                        </template>

                        <template v-slot:cell(amount_total)="row"> 
                          {{Intl.NumberFormat('es-AR',{style:'currency',currency: itemSelected.currency.code}).format(row.item.amount_total)}}           
                        </template>                        

                      </b-table>                      
                      <b-alert v-else variant="warning" show>No se encontraron imputaciones</b-alert>

                    </b-col>                    
                  </b-row>
                </b-col>
              </b-row>
            </b-card>           
          </div>
        </b-col>
      </b-row>    

      <b-row>
        <b-col lg="8" class="pr-0" id="view_footer_sales">  
          <b-card>
            <b-row>
              <b-col>
                <b-button type="button" variant="outline-dark" size="sm" @click="$router.go(-1)">
                  <i class="fa fa-angle-double-left"></i>
                  Volver
                </b-button>                        
              </b-col>

              <b-col>
                <nav v-if="table.tablePaginate">
                  <b-pagination class="pull-right mb-0"
                                size="sm"
                                pills=""
                                :total-rows="table.tablePaginate.total"
                                :per-page="table.tablePaginate.per_page"
                                v-model="table.currentPage"
                                @input="filterSales()" />
                </nav>
              </b-col>          
            </b-row>
          </b-card>
        </b-col>
      </b-row>

      <!--
      ###########################
      ######### PRINTER #########
      ###########################
      -->
      <Printer v-if="crud.print.id" 
              :reference="crud.print.reference" 
              :registerID="crud.print.id"
              :key="crud.print.key"/>

      <!-- ########################### -->
      <!-- #####     MODALES     ##### -->
      <!-- ########################### -->

      <!-- CRUD MAIN -->
      <b-modal v-model="modal.form.active"
              no-close-on-esc
              no-close-on-backdrop
              hide-header-close      
              header-bg-variant="dark"
              header-text-variant="white"
              size="xl">
        <div slot="modal-header">
          {{this.modal.form.title}}
        </div>

        <b-alert show variant="info" v-if="crud.form.remits" class="crud_sales_alert_from">          
          <b>Desde {{crud.form.remits.type_voucher.name + ' ' + crud.form.remits.point_sale.point_sale.toString().padStart(4,'0') + '-' + crud.form.remits.number.toString().padStart(8,'0')}}</b>
        </b-alert>

        <form-wizard  title=""
                      subtitle=""
                      nextButtonText="Siguiente"
                      backButtonText="Atrás"
                      finishButtonText="Guardar"
                      color="#2f353a"
                      shape="tab"
                      @on-complete="save"
                      class="crud-sales-wizard">

          <tab-content title="General" icon="fa fa-edit" :before-change="saleValidCrudGeneral">          
            <b-row>      
              <b-col md="4">
                <FindObject render="search"
                            type="customers" 
                            @select-object="loadCustomers($event)" 
                            :valueID="crud.form.customers_id"
                            :where="conditionCustomersActive"
                            :key="crud.form.customers_id"
                            :disabled="crud.form.id>0 || invoiceFrom" />                 
              </b-col>                  
              <b-col md="4">
                <b-form-group label="Fecha">
                  <b-form-datepicker size="sm" v-model="crud.form.date" placeholder="Fecha" local="es"></b-form-datepicker>                      
                </b-form-group>            
              </b-col>
              <b-col md="4">
                <b-form-group label="Fecha Vencimiento">
                  <b-form-datepicker size="sm" v-model="crud.form.date_expiration" placeholder="Fecha Vencimiento" local="es"></b-form-datepicker>                      
                </b-form-group>            
              </b-col>
            </b-row>              
            <b-row> 
              <b-col md="4">
                <FindObject render="search"
                            type="erpPointsSales" 
                            @select-object="loadPointsSales($event)" 
                            :valueID="crud.form.points_sales_id"
                            :key="crud.form.points_sales_id"
                            :disabled="crud.form.id>0 || invoiceFrom"  />                 
              </b-col>
              <b-col md="4">
                <FindObject render="search"
                            type="erpMethodsPayment" 
                            @select-object="loadMethodsPayment($event)" 
                            :valueID="crud.form.methods_payment_id"
                            :key="crud.form.methods_payment_id" 
                            :disabled="crud.form.id>0 || invoiceFrom" />                 
              </b-col>
              <b-col md="4">
                <FindObject render="search"
                            type="priceList" 
                            @select-object="loadPriceList($event)" 
                            :valueID="crud.form.price_list_id"
                            :key="crud.form.price_list_id"
                            :where="conditionPriceListActive"
                            :disabled="crud.form.id>0 || invoiceFrom" />                 
              </b-col>
            </b-row>
            <b-row v-if="parameters.haveAcopio && crud.form.selectTypeVoucher!='notacredito'">
              <b-col md="4">
                <FindObject render="search"
                            type="erpMethodsAcopio" 
                            @select-object="loadMethodsAcopio($event)" 
                            :valueID="crud.form.methods_acopio_id"
                            :key="crud.form.methods_acopio_id" 
                            :disabled="crud.form.id>0 || invoiceFrom" />                 
              </b-col>                 
            </b-row>            
            <b-row v-if="balanceCurrentAccount<0 && crud.form.selectTypeVoucher=='notacredito'">
              <b-col>
                <b-alert show variant="danger">
                  Se encontró saldo a favor del cliente: <b>({{Intl.NumberFormat('es-AR',{style:'currency',currency: getCurrency}).format(balanceCurrentAccount)}})</b>
                </b-alert>
              </b-col>
            </b-row>   

            <b-row v-if="crud.form.points_sales && !crud.form.points_sales.nd">
              <b-col>
                <div v-if="crud.form.points_sales.status">
                  <b-alert show variant="info" v-if="crud.form.points_sales.afip_production">
                    Punto de venta conectado a los servidores de AFIP <b>"EN PRODUCCIÓN"</b>
                  </b-alert>
                  <b-alert show variant="warning" v-else>
                    Punto de venta conectado a los servidores de AFIP <b>"HOMOLOGACION"</b>
                  </b-alert>                  
                </div>
                <div v-else>
                  <b-alert show variant="danger">
                    El punto de venta está <b>INACTIVO</b>
                  </b-alert>
                </div>
              </b-col>
            </b-row>                      
          </tab-content>

          <tab-content title="Detalle" icon="fa fa-list" :before-change="saleValidCrudDetail">          
            <b-row>
              <b-col lg="12" md="12" sm="12">             
                  <Detail :customer_id="crud.form.customers_id" 
                          :price_list_id="crud.form.price_list_id"                   
                          @load-detail-products="setDetailItems($event, 'products')" 
                          @load-detail-products-compound="setDetailItems($event, 'productsCompound')" 
                          @load-detail-services="setDetailItems($event, 'services')" 
                          @load-detail-concepts="setDetailItems($event, 'concepts')" 
                          :detailProducts="crud.form.detailProducts"
                          :detailProductsCompound="crud.form.detailProductsCompound"
                          :detailServices="crud.form.detailServices"
                          :detailConcepts="crud.form.detailConcepts"
                          :hideConcepts="false"
                          :onlyDiscountProduct="invoiceFrom"/>                  
                  
                  <b-row class="mt-3 p-3" align-v="end">
                    <b-col md="2">
                      <b-row>
                        <b-col md="5" class="crud-sales-totales-items-header">
                          ITEMS
                        </b-col>
                        <b-col md="7" class="crud-sales-counts-items">
                          <b>{{this.getCantItemSale}}</b>
                        </b-col>
                      </b-row>
                    </b-col>
                    <b-col md="6"></b-col>
                    <b-col md="4" v-if="getCurrencySale">
                      <b-row>
                        <b-col md="6" class="crud-sales-totales-items-header">
                          SUBTOTAL
                        </b-col>
                        <b-col md="6" class="crud-sales-totales-items">
                          {{Intl.NumberFormat('es-AR',{style:'currency',currency: getCurrencySale}).format(totals.subtotal)}}
                        </b-col>                    
                      </b-row>
                      
                      <b-row>
                        <b-col md="6" class="crud-sales-totales-items-header crud-sales-totales-discounts-items">
                          DESCUENTO (%)
                        </b-col>                     
                        <b-col md="6" class="crud-sales-totales-items">
                          <b-row>
                            <b-col class="p-0">
                              <b-form-input v-model="crud.form.percentage_discount" 
                                    type="number" 
                                    min="0" 
                                    max="100"
                                    step="1" 
                                    class="form-control"
                                    size="sm"
                                    @change="validDiscounts()"/>                                                  
                            </b-col>
                            <b-col class="crud-sales-totales-discounts-items">
                              {{Intl.NumberFormat('es-AR',{style:'currency',currency: getCurrencySale}).format(totals.discount)}}
                            </b-col>
                          </b-row>
                        </b-col>                    
                      </b-row> 
                      <b-row>
                        <b-col md="6" class="crud-sales-totales-items-header">
                          SUBTOTAL
                        </b-col>
                        <b-col md="6" class="crud-sales-totales-items">
                          {{Intl.NumberFormat('es-AR',{style:'currency',currency: getCurrencySale}).format(totals.subtotal_discount)}}
                        </b-col>                    
                      </b-row>                      
                      <b-row>
                        <b-col md="6" class="crud-sales-totales-items-header">
                          IVA
                        </b-col>
                        <b-col md="6" class="crud-sales-totales-items">
                          {{Intl.NumberFormat('es-AR',{style:'currency',currency: getCurrencySale}).format(totals.iva)}}
                        </b-col>                    
                      </b-row>            
                      <b-row>
                        <b-col md="6" class="crud-sales-totales-items-header">
                          TOTAL
                        </b-col>
                        <b-col md="6" class="crud-sales-totales-items-amount">
                          <b>{{Intl.NumberFormat('es-AR',{style:'currency',currency: getCurrencySale}).format(totals.total)}}</b>
                        </b-col>                    
                      </b-row>                                                                  
                    </b-col>
                  </b-row>
                  
              </b-col>        
            </b-row>
          </tab-content>

          <tab-content title="Facturas" icon="fa fa-link" :before-change="saleValidCrudRelation" v-if="crud.form.selectTypeVoucher=='notacredito'">
            <b-row>
              <b-col v-if="true">
                <VoucherRelation  :customer_id="crud.form.customers_id" 
                                  :point_sale_id="crud.form.points_sales_id"
                                  :details="crud.form.invoiceRelated"
                                  :amount="totals.total"
                                  :currency="(crud.form.price_list && crud.form.price_list.currency) ? crud.form.price_list.currency : {code:'ARS', symbol:'$'}"
                                  :singleVoucher="(crud.form.detailProducts.length>0 || crud.form.detailProductsCompound.length>0)"
                                  v-if="crud.form.price_list.currency"
                                  :key="'invoice_related_trashed_' + totals.total"
                                  @getInvoice="getInvoiceRelated($event)" />
              </b-col>
              <b-col v-else>
                <b-alert variant="warning" show>
                  <h4 class="alert-heading">No se encontró la moneda</h4>
                  <p>
                    Debe seleccionar una lista de precios para poder buscar una factura relacionada
                  </p>
                </b-alert>  
              </b-col>                
            </b-row>
          </tab-content>

          <tab-content title="Asiento Contable" icon="fa fa-balance-scale" :before-change="salesValidCrudAccounting" v-if="showContabilidad"> 
            <div v-if="contabilidad.render" :key="'accounting_' + itemAccountingForceUpdate">
              <AccountingEntries  :typeDebe="contabilidad.typeDebe"
                                  :typeHaber="contabilidad.typeHaber"
                                  :defaultDebe="contabilidad.defaultDebe"
                                  :defaultHaber="contabilidad.defaultHaber"
                                  :reference="contabilidad.reference"
                                  :amountTotal="contabilidad.amountTotal"
                                  @getAsiento="getAsiento($event)" />         
            </div>
          </tab-content>

          <tab-content title="Observaciones" icon="fa fa-flag-checkered">          
            <b-row>
              <b-col md="12">
                <b-form-group label="Observaciones">
                  <b-form-textarea                
                    v-model="crud.form.observations"
                    placeholder="Ingresar las observaciones..."
                    rows="3"
                    max-rows="6"
                    lazy
                  ></b-form-textarea>
                </b-form-group>
              </b-col>         
            </b-row>
          </tab-content>

        </form-wizard>
        
        <div slot="modal-footer">
          <b-button variant="outline-secondary" class="mr-1" @click="cancel()">Cancelar</b-button>              
        </div>                
      </b-modal>

      <!-- CRUD DISCOUNTS (NOTA DE CREDITO) -->
      <b-modal v-model="modal.discounts.active"
              no-close-on-esc
              no-close-on-backdrop
              hide-header-close      
              header-bg-variant="dark"
              header-text-variant="white"
              size="xl">
        <div slot="modal-header">
          {{this.modal.discounts.title}}
        </div>

        <form-wizard  title=""
                      subtitle=""
                      nextButtonText="Siguiente"
                      backButtonText="Atrás"
                      finishButtonText="Guardar"
                      color="#2f353a"
                      shape="tab"
                      @on-complete="save"
                      class="crud-sales-wizard">

          <tab-content title="General" icon="fa fa-edit" :before-change="saleValidCrudGeneralDiscounts">          
            <b-row>                     
              <b-col md="4">
                <b-form-group label="Fecha">
                  <b-form-datepicker size="sm" v-model="crud.form.date" placeholder="Fecha" local="es"></b-form-datepicker>                      
                </b-form-group>            
              </b-col>             
            </b-row>              
            <b-row> 
             <b-col md="4">
                <FindObject render="search"
                            type="customers" 
                            @select-object="loadCustomers($event)" 
                            :valueID="crud.form.customers_id"
                            :where="conditionCustomersActive"
                            :key="crud.form.customers_id"
                            :disabled="crud.form.id>0 || invoiceFrom" />                 
              </b-col>                 
              <b-col md="4">
                <FindObject render="search"
                            type="erpPointsSales" 
                            @select-object="loadPointsSales($event)" 
                            :valueID="crud.form.points_sales_id"
                            :key="crud.form.points_sales_id"
                            :disabled="crud.form.id>0 || invoiceFrom"  />                 
              </b-col>                
              <b-col md="4">
                <FindObject render="search"
                            type="concepts"                             
                            @select-object="loadConcepts($event)" 
                            :valueID="crud.form.concepts_id"
                            :key="crud.form.points_sales_id"
                            :disabled="crud.form.id>0 || invoiceFrom" />           
              </b-col>                
            </b-row>
            <b-row v-if="balanceCurrentAccount<0 && crud.form.selectTypeVoucher=='notacredito'">
              <b-col>
                <b-alert show variant="danger">
                  Se encontró saldo a favor del cliente: <b>({{Intl.NumberFormat('es-AR',{style:'currency',currency: getCurrency}).format(balanceCurrentAccount)}})</b>
                </b-alert>
              </b-col>
            </b-row>  

            <b-row v-if="crud.form.points_sales && !crud.form.points_sales.nd">
              <b-col>
                <div v-if="crud.form.points_sales.status">
                  <b-alert show variant="info" v-if="crud.form.points_sales.afip_production">
                    Punto de venta conectado a los servidores de AFIP <b>"EN PRODUCCIÓN"</b>
                  </b-alert>
                  <b-alert show variant="warning" v-else>
                    Punto de venta conectado a los servidores de AFIP <b>"HOMOLOGACION"</b>
                  </b-alert>                  
                </div>
                <div v-else>
                  <b-alert show variant="danger">
                    El punto de venta está <b>INACTIVO</b>
                  </b-alert>
                </div>
              </b-col>
            </b-row>                            
          </tab-content>

          <tab-content title="Imputación" icon="fa fa-paperclip" :before-change="saleValidCrudImputationDiscounts">                                  
            <b-row>
              <b-col lg="12" md="12" sm="12">
                <b-table-simple hover small caption-top responsive v-if="arr.pending.length" class="crud-sales-table-items">
                  <b-thead head-variant="dark">                    
                    <b-tr>
                      <b-th width="7%" class="text-left">Aplicar</b-th>
                      <b-th width="10%" class="text-left">Fecha</b-th>
                      <b-th width="33%" class="text-left">Comprobante</b-th>
                      <b-th width="10%" class="text-right">Total</b-th>
                      <b-th width="10%" class="text-right">Pendiente</b-th>
                      <b-th width="10%" class="text-center">Desc (%)</b-th>
                      <b-th width="10%" class="text-right">Desc ($)</b-th>
                      <b-th width="10%" class="text-right">Desc + Imp</b-th>
                    </b-tr>
                  </b-thead>      
                  <b-tbody v-for="(item, index) in arr.pending" :key="item.id">                         
                    <b-tr :key="itemForceUpdate" v-if="crud.formPending[index]">
                      <b-td class="text-left align-middler">
                        <b-form-checkbox v-model="crud.formPending[index].check" 
                                          @change="validCheck(index)"
                                          switch 
                                          size="sm" 
                                          class="pull-left">
                        </b-form-checkbox> 
                      </b-td>
                      <b-td class="text-left align-middle">
                        {{moment(item.date).format('DD/MM/YYYY')}}
                      </b-td>
                      <b-td class="text-left align-middle">                        
                        {{item.type_voucher.name + ' ' + item.point_sale.point_sale.toString().padStart(4,'0') + '-' + item.number.toString().padStart(8,'0'),}}
                      </b-td>
                      <b-td class="text-right align-middle">
                        <div style="color:red;" v-if="item.type_voucher.type_balance == '-'">
                          <b>{{Intl.NumberFormat('es-AR',{style:'currency',currency: item.currency.code}).format(item.amount_total)}}</b>
                        </div>
                        <div style="color:green;" v-else>
                          <b>{{Intl.NumberFormat('es-AR',{style:'currency',currency: item.currency.code}).format(item.amount_total)}}</b>
                        </div>
                      </b-td>                      
                      <b-td class="text-right align-middle">
                        <div style="color:red;" v-if="item.type_voucher.type_balance == '-'">
                          <b>{{Intl.NumberFormat('es-AR',{style:'currency',currency: item.currency.code}).format(calcVoucherPending(item))}}</b>
                        </div>
                        <div style="color:green;" v-else>
                          <b>{{Intl.NumberFormat('es-AR',{style:'currency',currency: item.currency.code}).format(calcVoucherPending(item))}}</b>
                        </div>                        
                      </b-td>                      
                      <b-td class="text-center align-middle">
                        <b-form-input type="number"
                                      size="sm"
                                      step="0.01"
                                      min="0"                                      
                                      max="100"                                      
                                      @change="validAmount(index)"
                                      v-model="crud.formPending[index].discounts"                                      
                                      placeholder="Ingresar monto a imputar"
                                      class="crud-sales-item-input"
                                      v-if="crud.formPending[index].check">
                        </b-form-input>                        
                      </b-td> 
                      <b-td class="text-right align-middle">                        
                        <b>{{Intl.NumberFormat('es-AR',{style:'currency',currency: item.currency.code}).format(crud.formPending[index].amount)}}</b>
                      </b-td>
                      <b-td class="text-right align-middle">                        
                        <b>{{Intl.NumberFormat('es-AR',{style:'currency',currency: item.currency.code}).format(crud.formPending[index].amount_total)}}</b>
                      </b-td>                                                                 
                    </b-tr>                    
                  </b-tbody>                              
                </b-table-simple>   
                <div v-else>
                  <b-alert show variant="warning">
                    No hay comprobante para imputar.
                  </b-alert>                
                </div>
              </b-col> 
            </b-row>            
            <b-row align-h="end" class="mb-2">
              <b-col lg="4" md="4" sm="4">
                <b-input-group size="sm" prepend="DESCUENTO">
                  <b-form-input v-model="calc.discounts" 
                                type="text"
                                :readonly="true"
                                class="crud-sales-add-total-final">
                  </b-form-input>   
                </b-input-group>                
              </b-col>
            </b-row>                        
          </tab-content>

          <tab-content title="Asiento Contable" icon="fa fa-balance-scale" :before-change="salesValidCrudAccounting" v-if="showContabilidad"> 
            <div v-if="contabilidad.render" :key="'accounting_' + itemAccountingForceUpdate">
              <AccountingEntries  :typeDebe="contabilidad.typeDebe"
                                  :typeHaber="contabilidad.typeHaber"
                                  :defaultDebe="contabilidad.defaultDebe"
                                  :defaultHaber="contabilidad.defaultHaber"
                                  :reference="contabilidad.reference"
                                  :amountTotal="contabilidad.amountTotal"
                                  @getAsiento="getAsiento($event)" />         
            </div>
          </tab-content>

          <tab-content title="Observaciones" icon="fa fa-flag-checkered">          
            <b-row>
              <b-col md="12">
                <b-form-group label="Observaciones">
                  <b-form-textarea                
                    v-model="crud.form.observations"
                    placeholder="Ingresar las observaciones..."
                    rows="3"
                    max-rows="6"
                  ></b-form-textarea>
                </b-form-group>
              </b-col>         
            </b-row>
          </tab-content>

        </form-wizard>
        
        <div slot="modal-footer">
          <b-button variant="outline-secondary" class="mr-1" @click="cancel()">Cancelar</b-button>              
        </div>                
      </b-modal>

      <!-- CRUD DETAILS -->
      <b-modal v-model="modal.detail.active"
              header-bg-variant="dark"
              header-text-variant="white"
              size="xl">
        <div slot="modal-header">
          {{this.modal.detail.title}}
        </div>

        <b-row>
          <b-col sm="12">                     
            <b-tabs active-nav-item-class="font-weight-bold text-uppercase text-dark">
              <b-tab title="General">
                <table class="table table-hover table-sm" v-if="itemSelected">
                  <tbody>
                    <tr>
                      <td class="crud-sales-title-table-custom align-middle">ID</td>
                      <td class="crud-sales-value-table-custom align-middle">
                        <b>#{{this.itemSelected.id}}</b>
                      </td>
                    </tr>                     
                    <tr v-if="itemSelected.type_voucher && itemSelected.point_sale">
                      <td class="crud-sales-title-table-custom align-middle">Comprobante</td>                      
                      <td class="crud-sales-value-table-custom align-middle">
                        <b>{{this.itemSelected.type_voucher.name + ' '  + this.itemSelected.point_sale.point_sale.toString().padStart(4,'0') + '-' + this.itemSelected.number.toString().padStart(8,'0')}}</b>
                      </td>
                    </tr>         
                    <tr v-if="itemSelected.remit && itemSelected.remit.type_voucher && itemSelected.remit.point_sale">
                      <td class="crud-sales-title-table-custom align-middle">Remito Vinculado</td>                      
                      <td class="crud-sales-value-table-custom align-middle">
                        {{this.itemSelected.remit.type_voucher.name + ' '  + this.itemSelected.remit.point_sale.point_sale.toString().padStart(4,'0') + '-' + this.itemSelected.remit.number.toString().padStart(8,'0')}}
                      </td>
                    </tr>                                                                               
                    <tr v-if="itemSelected.staff || itemSelected.seller">
                      <td class="crud-sales-title-table-custom align-middle">Emisor</td>                      
                      <td class="crud-sales-value-table-custom align-middle">
                        <div v-if="itemSelected.seller">
                          {{itemSelected.seller.name}}  
                        </div>
                        <div v-else>
                          {{itemSelected.staff.name}}  
                        </div>                        
                      </td>
                    </tr>                                                             
                    <tr>
                      <td class="crud-sales-title-table-custom align-middle">Fecha</td>
                      <td class="crud-sales-value-table-custom align-middle">
                        {{moment(this.itemSelected.date).format('DD MMMM YYYY')}}
                      </td>
                    </tr>                      
                    <tr>
                      <td class="crud-sales-title-table-custom align-middle">Fecha Vencimiento</td>
                      <td class="crud-sales-value-table-custom align-middle">
                        {{moment(this.itemSelected.date_expiration).format('DD MMMM YYYY')}}
                      </td>
                    </tr>                                          
                    <tr v-if="itemSelected.point_sale">
                      <td class="crud-sales-title-table-custom align-middle">Punto de Venta</td>
                      <td class="crud-sales-value-table-custom align-middle">
                        {{this.itemSelected.point_sale.point_sale}} - {{this.itemSelected.point_sale.name}}                          
                      </td>
                    </tr>                                           
                    <tr v-if="itemSelected.customer">
                      <td class="crud-sales-title-table-custom align-middle">Cliente</td>
                      <td class="crud-sales-value-table-custom align-middle">
                        {{this.itemSelected.customer.name}}                        
                      </td>
                    </tr>                      
                    <tr v-if="itemSelected.method_payment">
                      <td class="crud-sales-title-table-custom align-middle">Método de Pago</td>
                      <td class="crud-sales-value-table-custom align-middle">
                        {{this.itemSelected.method_payment.name}}                        
                      </td>
                    </tr>       
                    <tr v-if="itemSelected.method_acopio && parameters.haveAcopio">
                      <td class="crud-sales-title-table-custom align-middle">Método de Acopio</td>
                      <td class="crud-sales-value-table-custom align-middle">
                        {{this.itemSelected.method_acopio.name}}                        
                      </td>
                    </tr>                                               
                    <tr v-if="itemSelected.currency && itemSelected.amount_discount > 0">
                      <td class="crud-sales-title-table-custom align-middle">Neto</td>
                      <td class="crud-sales-value-table-custom align-middle">                                    
                        {{Intl.NumberFormat('es-AR',{style:'currency',currency:this.itemSelected.currency.code}).format(parseFloat(this.itemSelected.amount_net) + parseFloat(this.itemSelected.amount_discount))}}                        
                      </td>
                    </tr>                           
                    <tr v-if="itemSelected.currency && itemSelected.amount_discount > 0">
                      <td class="crud-sales-title-table-custom align-middle">Descuento ({{itemSelected.percentage_discount}} %)</td>
                      <td class="crud-sales-value-table-custom align-middle text-danger">                                    
                        ({{Intl.NumberFormat('es-AR',{style:'currency',currency:this.itemSelected.currency.code}).format(this.itemSelected.amount_discount)}})
                      </td>
                    </tr>                                                                 
                    <tr v-if="itemSelected.currency">
                      <td class="crud-sales-title-table-custom align-middle">SubTotal</td>
                      <td class="crud-sales-value-table-custom align-middle">                                    
                        {{Intl.NumberFormat('es-AR',{style:'currency',currency:this.itemSelected.currency.code}).format(this.itemSelected.amount_net)}}                        
                      </td>
                    </tr>                                                           
                    <tr v-if="itemSelected.currency">
                      <td class="crud-sales-title-table-custom align-middle">IVA</td>
                      <td class="crud-sales-value-table-custom align-middle">                                    
                        {{Intl.NumberFormat('es-AR',{style:'currency',currency:this.itemSelected.currency.code}).format(this.itemSelected.amount_iva)}}                        
                      </td>
                    </tr>                                          
                    <tr v-if="itemSelected.currency">
                      <td class="crud-sales-title-table-custom align-middle">Total</td>
                      <td class="crud-sales-value-table-custom align-middle">    
                        <div style="color:red" v-if="itemSelected.type_voucher.type_balance == '-'">                    
                          <b>({{Intl.NumberFormat('es-AR',{style:'currency',currency:this.itemSelected.currency.code}).format(this.itemSelected.amount_total)}})</b>                        
                        </div>
                        <div v-else>                    
                          <b>{{Intl.NumberFormat('es-AR',{style:'currency',currency:this.itemSelected.currency.code}).format(this.itemSelected.amount_total)}}</b>                        
                        </div>
                      </td>
                    </tr>          
                    <tr v-if="(itemSelected.amount_impute!=itemSelected.amount_total) && itemSelected.type_voucher.type_balance=='+'">
                      <td class="crud-sales-title-table-custom align-middle">Pendiente de Imputación</td>
                      <td class="crud-sales-value-table-custom align-middle table-warning">    
                        <b>{{Intl.NumberFormat('es-AR',{style:'currency',currency:this.itemSelected.currency.code}).format(getPendingVoucherSale(this.itemSelected))}}</b>                        
                      </td>
                    </tr>                                    
                    <tr v-if="itemSelected.observations">
                      <td class="crud-sales-title-table-custom align-middle">Observaciones</td>
                      <td class="crud-sales-value-table-custom align-middle">                        
                        {{this.itemSelected.observations}}
                      </td>
                    </tr>                                               
                  </tbody>
                </table>
              </b-tab>  

              <b-tab title="Items">                
                <b-table class="mb-0 table-full-detail-sales"
                        responsive
                        head-variant="dark"
                        :hover="true"
                        :small="true"
                        :fixed="true"
                        :items="tableDetail.items"
                        :fields="tableDetail.fields"
                        v-if="tableDetail.items.length">                       
                    
                  <template v-slot:table-colgroup="scope">
                    <col
                      v-for="field in scope.fields"                    
                      :key="field.key"
                      :style="{ width: field.width }"
                    >
                  </template>           

                  <template v-slot:cell(code)="data">                           
                    <div v-html="getDetailCode(data.item)"></div>
                  </template>

                  <template v-slot:cell(product)="data">                           
                    <div v-html="getDetailName(data.item)"></div>
                  </template>

                  <template v-slot:cell(list)="data">                                                                   
                    <span v-if="data.item.price_list">
                      {{data.item.price_list.reference}}
                    </span>
                    <span v-else>
                      <span v-if="itemSelected.price_list">
                        {{itemSelected.price_list.reference}}
                      </span>
                    </span>
                  </template>

                  <template v-slot:cell(quantity)="data">   
                    {{data.item.quantity}}                        
                    <span v-if="data.item.unit_measurement">{{data.item.unit_measurement.reference}}</span>
                  </template>

                  <template v-slot:cell(unit_price)="data">                          
                    <div v-if="itemSelected.currency">                      
                      {{Intl.NumberFormat('es-AR',{style:'currency',currency:itemSelected.currency.code}).format(data.item.price_unit)}}                      
                    </div>                    
                  </template>                  

                  <template v-slot:cell(amount_net)="data">                          
                    <div v-if="itemSelected.currency">                      
                      {{Intl.NumberFormat('es-AR',{style:'currency',currency:itemSelected.currency.code}).format(data.item.amount_net)}}                      
                    </div>                    
                  </template>  

                  <template v-slot:cell(subtotal)="data">                          
                    <div v-if="itemSelected.currency">                      
                      {{Intl.NumberFormat('es-AR',{style:'currency',currency:itemSelected.currency.code}).format(parseFloat(data.item.quantity) * parseFloat(data.item.price_unit))}}                      
                    </div>                    
                  </template>                  

                  <template v-slot:cell(amount_iva)="data">                          
                    <div v-if="itemSelected.currency">                      
                      {{Intl.NumberFormat('es-AR',{style:'currency',currency:itemSelected.currency.code}).format(data.item.amount_iva)}}                      
                    </div>                    
                  </template>                  

                  <template v-slot:cell(amount_discount)="data">                          
                    <div v-if="itemSelected.currency" :class="{'text-danger':parseFloat(data.item.percentage_discount)>0}">  
                      {{parseFloat(data.item.percentage_discount).toFixed(0)}}%
                      ({{Intl.NumberFormat('es-AR',{style:'currency',currency:itemSelected.currency.code}).format(data.item.amount_discount)}})
                    </div>                    
                  </template>                  
                  
                  <template v-slot:cell(amount_total)="data">                           
                    <div v-if="itemSelected.currency">
                      {{Intl.NumberFormat('es-AR',{style:'currency',currency:itemSelected.currency.code}).format(data.item.amount_total)}}
                    </div>                    
                  </template>                  
                </b-table>
                <b-alert v-else variant="warning" show>No se encontraron registros</b-alert>
              </b-tab>     

              <b-tab title="Imputaciones" v-if="tableImputation.items.length">                
                <b-table class="mb-0 table-full-detail-sales"
                        responsive
                        head-variant="dark"
                        :hover="true"
                        :small="true"
                        :fixed="true"
                        :items="tableImputation.items"
                        :fields="tableImputation.fields">                       
                    
                  <template v-slot:table-colgroup="scope">
                    <col
                      v-for="field in scope.fields"                    
                      :key="field.key"
                      :style="{ width: field.width }"
                    >
                  </template>           

                  <template v-slot:cell(date)="data"> 
                    {{moment(data.item.date).format('DD/MM/YYYY')}}
                  </template>              

                  <template v-slot:cell(reference)="data"> 
                    {{data.item.reference}}                          
                  </template>

                  <template v-slot:cell(amount_total)="data"> 
                    {{Intl.NumberFormat('es-AR',{style:'currency',currency: itemSelected.currency.code}).format(data.item.amount_total)}}           
                  </template>         

                </b-table>
              </b-tab>

              <b-tab title="Asiento" v-if="showContabilidad">
                <b-table class="mb-0 table-full-detail-sales"
                        responsive
                        head-variant="dark"
                        :hover="true"
                        :small="true"
                        :fixed="true"
                        :items="tableAccounting.items"
                        :fields="tableAccounting.fields"
                        v-if="tableAccounting.items.length">                       
                    
                  <template v-slot:table-colgroup="scope">
                    <col
                      v-for="field in scope.fields"                    
                      :key="field.key"
                      :style="{ width: field.width }"
                    >
                  </template>                                                                                     

                  <template v-slot:cell(code)="data">
                    <div v-if="data.item.type=='Debe'">
                      <span>{{data.item.accounting_accounts_code}}</span>
                    </div>
                    <div v-if="data.item.type=='Haber'">
                      <span class="ml-5">{{data.item.accounting_accounts_code}}</span>
                    </div>
                  </template>  

                  <template v-slot:cell(name)="data">
                    <div v-if="data.item.type=='Debe'">
                      <span>{{data.item.accounting_accounts_name}}</span>
                    </div>
                    <div v-if="data.item.type=='Haber'">
                      <span class="ml-5">{{data.item.accounting_accounts_name}}</span>
                    </div>                                                          
                  </template>

                  <template v-slot:cell(debit)="data">
                    <span v-if="data.item.type == 'Debe'">
                      {{Intl.NumberFormat('es-AR',{style:'currency',currency: getCurrency}).format(data.item.total)}}
                    </span>
                  </template>

                  <template v-slot:cell(credit)="data">
                    <span v-if="data.item.type == 'Haber'">
                      {{Intl.NumberFormat('es-AR',{style:'currency',currency: getCurrency}).format(data.item.total)}}
                    </span>
                  </template>
                </b-table>
                <b-alert v-else variant="warning" show>No se encontraron registros</b-alert>
              </b-tab>
            </b-tabs>

          </b-col>
        </b-row>

        <div slot="modal-footer">
          <b-button variant="outline-secondary" class="mr-1" @click="modal.detail.active=false">Cancelar</b-button>                   
        </div>
      </b-modal>
    </b-col>
  </b-row>
</template>
<script>
  import serviceAPI from './services'
  import Error from '@/handler/error'
  import ErrorToken from '@/handler/errorToken'
  import Session from '@/handler/session'  
  import Profiles from '@/config/profiles'
  import Modules from '@/config/modules'
  import Helper from '@/handler/helper' 
  import FindObject from '@/components/inc/find/findObject'  
  import Printer from '@/components/inc/printer/printer'
  import Detail from '@/components/inc/cpbtDetail/detail'
  import moment from 'moment'
  import AccountingEntries from '@/components/inc/accounting/entries'  
  import VoucherRelation from './inc/detailInvoice'
  import {FormWizard, TabContent} from 'vue-form-wizard'
  import 'vue-form-wizard/dist/vue-form-wizard.min.css'
  import Param from '@/config/parameters'  

  export default {
    components: {
      FindObject,
      Printer,
      Detail,
      FormWizard,
      TabContent,     
      AccountingEntries, 
      VoucherRelation,
    },
    data: () => {
      return {      
        access: {
          module_id: Modules.VENTAS,
          profile_id: Profiles.PERSONAL,
          view_reference: 'salesTrashed',
          elements: {     
            approvalSalesTrashed: true,
            disapproveSalesTrashed: true,                   
          }
        },
        parameters: {
          haveAcopio: Helper.hasParametersAccess(Param.P49),          
        },         
        table : {
          items: [],
          fields: [],
          filter: null,
          mostrarFiltros: true,
          currentPage: 1,
          perPage: 0,
          isBusy: false,
          tablePaginate: null,          
        },     
        tableSub : {
          items: [],
          fields: []
        },            
        tableDetail : {
          items: [],
          fields: []
        },            
        tableAccounting : {
          items: [],
          fields: []
        },
        tableImputation : {
          items: [],
          fields: []
        },        
        crud: {
          form: {
            id: 0,
            date: '',
            date_expiration: '',              
            types_vouchers_id: 0,
            types_vouchers: null,
            letter: '',
            number: 0,
            methods_payment_id: 0,
            methods_payment: null,
            price_list_id: 0,
            price_list: null,            
            points_sales_id: 0,
            points_sales: null,              
            observations: '',            
            staff_id: 0,
            sellers_id: 0,
            customers_id: 0,
            customers: null,   
            percentage_discount: 0,                                 
            detailProducts: [],
            detailProductsCompound: [],
            detailServices: [],
            detailConcepts: [],
            invoiceRelated: [],
            selectTypeVoucher: 'factura',
            selectDestination: 'default',
            accountingEntry: null,
            concepts: null,
            concepts_id: 0,
            remits: null,
            remits_id: 0,
            trashed: true,
            methods_acopio_id: 0,
            methods_acopio: null,              
          },          
          formPending: [],  
          print: {
            reference: '',
            id: 0,
            key: 0,
          }               
        },
        totals: {
          subtotal: 0,
          discount: 0,
          iva: 0,
          total: 0,
          arrIva: [],
        },
        modal: {
          form: {
            active: false,
            title: '',
          },
          detail: {
            active: false, 
            title: '',
          }, 
          discounts: {
            active: false, 
            title: '',            
          }           
        },          
        arr: {
          sales: [],   
          pending: [],
          export: [],
          ivaCondition: [],
          filters : {
            customers: [],
            sellers: [],
            points_sales: [],
            status_imputation: [
              {code: true, label: 'Cancelado'},
              {code: false, label: 'Pendiente'}
            ]
          },                       
        },           
        filters: {
          date_start: '',
          date_end: '',
          customers: null,
          sellers: null,
          points_sales: null,
          status_imputation: null,
          number: '',
        },     
        itemSelected: null, 
        itemForceUpdate: 0,
        itemAccountingForceUpdate: 0,    
        contabilidad: {
          render: false,
          typeDebe:"",                                 
          typeHaber:"",
          defaultDebe:[],
          defaultHaber:[],
          reference:'',
          amountTotal:0
        },
        aux: {
          detailProducts: [],
          detailProductsCompound: [],
          detailServices: [],
          detailConcepts: [],          
        },
        calc: {
          discounts: 0,
        },       
        balanceCurrentAccount: 0,                    
      }
    },
    created () {     
      /* Configurar permisos de vistas y elementos */
      Helper.hasAccessView(this.access)
      this.access.elements.approvalSalesTrashed = Helper.hasAccessElement(this.access, 'approval_salesTrashed')
      this.access.elements.disapproveSalesTrashed = Helper.hasAccessElement(this.access, 'disapprove_salesTrashed')
      /* Fin configuracion */
    },     
    mounted() {   
      this.loadFieldTable()
      this.filterLoad()   
      this.filterSales()              
      this.loadStyleConfig()

      this.getIvaCondition()       
    },    
    computed: {
      // MODULES
      moduleSellersActive() {
        var status = false
        var modules = Session.getSession().auth.user.permissions.modules        
        modules.forEach(element => {
          if(element.id == Modules.VENDEDORES) {                             
            status = true                       
          }
        })        
        return status
      },

      // MODULES
      showContabilidad() {
        var status = false
        var modules = Session.getSession().auth.user.permissions.modules        
        modules.forEach(element => {
          if(element.id == Modules.CONTABILIDAD) {                             
            status = true                       
          }
        })        
        return status
      },

      // OTHERS
      subtotalItemSelected() {
        if(this.itemSelected) {
          return parseFloat(this.itemSelected.amount_total)
        } else {
          return 0
        }
      },

      // CONDITIONS SELECT
      conditionCustomersActive(){
        return [{field: 'active', condition: true}];
      },      
      conditionPriceListActive(){
        return [{field: 'active', condition: true}];
      },      

      // CANT / CURRENCY      
      getCantItemSale() {
        var cant1 = this.crud.form.detailProducts.length
        var cant2 = this.crud.form.detailProductsCompound.length
        var cant3 = this.crud.form.detailServices.length
        var cant4 = this.crud.form.detailConcepts.length

        return parseFloat(cant1 + cant2 + cant3 + cant4)
      },
      getCurrencySale() {        
        var currency = 'ARS'
        if(this.crud.form.price_list) {                    
          if(this.crud.form.price_list.currency) {                   
            currency = this.crud.form.price_list.currency.code            
          }
        }         
        return currency                      
      },
      
      // VOUCHER
      getRefVoucher() {        
        if(this.crud.form.types_vouchers && this.crud.form.points_sales && this.crud.form.number) {
          return this.crud.form.types_vouchers.name + ' '  + this.crud.form.points_sales.point_sale.toString().padStart(4,'0') + '-' + this.crud.form.number.toString().padStart(8,'0')  
        } else {
          return ''
        }      
      },

      // CONFIGURACION      
      getCurrency() {
        return 'ARS'
      },

      // INVOICE DESDE
      invoiceFrom() {
        var status = false
        if(this.crud.form.remits_id) {
          status = true
        }
        if(this.crud.form.orders_id) {
          status = true
        }        
        if(this.crud.form.disabled) {
          status = true
        }
        return status
      }
    },
    watch: {        
      'crud.form.customers_id': function (newQuestion, oldQuestion) {         
        this.searchVoucherByCustomer()

        if(this.crud.form.selectDestination == 'discounts') {
          this.obtenerVouchersPendingImputarByCustomers()
          this.calc.discounts = 0
        } 
      },
      'crud.form.points_sales_id': function (newQuestion, oldQuestion) {                
        this.searchVoucherByCustomer()

        if(this.crud.form.selectDestination == 'discounts') {
          this.obtenerVouchersPendingImputarByCustomers()
          this.calc.discounts = 0
        } 
      },
      'crud.form.concepts_id': function (newQuestion, oldQuestion) {                        
        if(this.crud.form.selectDestination == 'discounts') {
          this.obtenerVouchersPendingImputarByCustomers()
          this.calc.discounts = 0
        } 
      },      
      'crud.form.detailProducts': function (newQuestion, oldQuestion) {                                
        this.searchTotalsTemporary()
      },
      'crud.form.detailProductsCompound': function (newQuestion, oldQuestion) {       
        this.searchTotalsTemporary()
      },        
      'crud.form.detailServices': function (newQuestion, oldQuestion) {        
        this.searchTotalsTemporary()
      },
      'crud.form.detailConcepts': function (newQuestion, oldQuestion) {
        this.searchTotalsTemporary()
      }            
    },    
    methods: {
      // CONFIGURACION
      loadStyleConfig() {        
        var view_list_sales = document.getElementById('view_list_sales')
        var view_footer_sales = document.getElementById('view_footer_sales')

        var padre = document.getElementById('contain_details_items')
        var hijo = document.getElementById('details_items')  
              
        hijo.style.width = padre.clientWidth - 45 + 'px'

        if(window.innerWidth < 991) {
          hijo.style.width = '100%'
          hijo.style.position = "relative"
          hijo.style.right = 'auto'

          view_list_sales.classList.remove('pr-0')
          view_footer_sales.classList.remove('pr-0')
        } else {          
          view_list_sales.classList.add('pr-0')
          view_footer_sales.classList.add('pr-0')
        }        
      },
      loadFieldTable() {
        // TABLE COMPROBANTES
        this.table.fields.push({key: 'id', label: 'Nº', sortable: true, class:"align-middle text-center", width:"120px"})
        this.table.fields.push({key: 'date', label: 'Fecha', class:"align-middle text-center", width:"80px"})
        this.table.fields.push({key: 'date_expiration', label: 'Vto', class:"align-middle text-center", width:"80px"})
        this.table.fields.push({key: 'customers_id', label: 'Cliente', class:"align-middle", width:"200px"})                      

        if(this.moduleSellersActive){
          this.table.fields.push({key: 'sellers_id', label: 'Vendedor', class:"align-middle text-center", width:"60px"})          
        }

        this.table.fields.push({key: 'staff_id', label: 'Staff', class:"align-middle text-center", width:"60px"})
        this.table.fields.push({key: 'amount_total', label: 'Total', class:"align-middle text-right", width:"90px"})                      
        this.table.fields.push({key: 'amount_pending', label: 'Pendiente', class:"align-middle text-right", width:"90px"})                      
        this.table.fields.push({key: 'observations', label: '', class:"align-middle text-center", width:"20px"})                        
        this.table.fields.push({key: 'f_action', label:'', class:"align-middle", width:"40px"})

        // TABLE DETALLE
        this.tableSub.fields.push({key: 'quantity', label: 'Cantidad', class:"align-middle", width:"50px"})                
        this.tableSub.fields.push({key: 'product', label: 'Item', class:"align-middle", width:"150px"})                

        // TABLE DETALLE COMPLETO        
        this.tableDetail.fields.push({key: 'code', label: 'Código', class:"align-middle", width:"50px"})                
        this.tableDetail.fields.push({key: 'product', label: 'Item', class:"align-middle text-truncate", width:"200px"})                
        this.tableDetail.fields.push({key: 'list', label: 'Lista', class:"align-middle text-truncate", width:"50px"})                        
        this.tableDetail.fields.push({key: 'quantity', label: 'Cant', class:"align-middle text-right", width:"60px"})                
        this.tableDetail.fields.push({key: 'unit_price', label: 'P.Unit', class:"align-middle text-right", width:"70px"})                
        this.tableDetail.fields.push({key: 'subtotal', label: 'Subtotal', class:"align-middle text-right", width:"70px"})                
        this.tableDetail.fields.push({key: 'amount_discount', label: 'Desc', class:"align-middle text-right", width:"70px"})                
        this.tableDetail.fields.push({key: 'amount_net', label: 'P.Neto', class:"align-middle text-right", width:"70px"})                       
        this.tableDetail.fields.push({key: 'amount_iva', label: 'IVA', class:"align-middle text-right", width:"70px"})                
        this.tableDetail.fields.push({key: 'amount_total', label: 'Total', class:"align-middle text-right", width:"70px"})

        // TABLE ASIENTO
        this.tableAccounting.fields.push({key: 'code', label: 'Código', class:"align-middle", width:"150px"})                
        this.tableAccounting.fields.push({key: 'name', label: 'Nombre', class:"align-middle", width:"250px"})                
        this.tableAccounting.fields.push({key: 'debit', label: 'Debe', class:"align-middle", width:"150px"})                
        this.tableAccounting.fields.push({key: 'credit', label: 'Haber', class:"align-middle", width:"150px"})
        
        // TABLE IMPUTACIONES
        this.tableImputation.fields.push({key: 'date', label: 'Fecha', class:"align-middle", width:"20%"})                
        this.tableImputation.fields.push({key: 'reference', label: 'Referencia', class:"align-middle", width:"50%"})           
        this.tableImputation.fields.push({key: 'amount_total', label: 'Total', class:"align-middle text-right", width:"30%"})     
      },      
      getRowCount(items) {
        return items.length
      },
      onRowSelected(item) {         
        this.arr.sales.forEach((element, index) => {
          this.table.items[index]._showDetails = false
          if(item.length){
            if(element.id == item[0].id) {  
              this.openSub(element)                             
            }
          }
        })
      },   
      setConfigTableSalesShow() {
        var arrTable = document.getElementsByClassName('table-sales-custom')
        var i = 0
        for(i=0; i<arrTable.length; i++) {          
          arrTable[i].style.minHeight = '150px'
        }        
      },
      setConfigTableSalesHide() {
        var arrTable = document.getElementsByClassName('table-sales-custom')
        var i = 0
        for(i=0; i<arrTable.length; i++) {
          arrTable[i].style.minHeight = 'auto'
        }        
      },  
      setConfigTableSubShow() {
        var arrTable = document.getElementsByClassName('table-sales-sub')
        var i = 0
        for(i=0; i<arrTable.length; i++) {
          arrTable[i].style.minHeight = '210px'
        }        
      },
      setConfigTableSubHide() {
        var arrTable = document.getElementsByClassName('table-sales-sub')
        var i = 0
        for(i=0; i<arrTable.length; i++) {
          arrTable[i].style.minHeight = 'auto'
        }        
      },  

      // SELECT
      loadCustomers (object) {        
        if(object){
          this.crud.form.customers = object
          this.crud.form.customers_id = object.id   
          
          if(object.data_fiscal) {
            if(object.data_fiscal.points_sales_id) {
              if(!this.crud.form.points_sales_id) {
                this.crud.form.points_sales = object.data_fiscal.points_sales
                this.crud.form.points_sales_id = object.data_fiscal.points_sales_id
              }
            }

            if(object.data_fiscal.methods_payment_id) {
              if(!this.crud.form.methods_payment_id) {
                this.crud.form.methods_payment = object.data_fiscal.methods_payment
                this.crud.form.methods_payment_id = object.data_fiscal.methods_payment_id    
                
                let days = parseInt(object.data_fiscal.methods_payment.term)
                this.crud.form.date_expiration = moment(this.crud.form.date).add(days,'d').format('YYYY-MM-DD')                
              }
            }

            if(object.data_fiscal.methods_acopio_id) {
              if(!this.crud.form.methods_acopio_id) {
                this.crud.form.methods_acopio = object.data_fiscal.methods_acopio
                this.crud.form.methods_acopio_id = object.data_fiscal.methods_acopio_id
              }
            }                 
          }
          
          if(!this.crud.form.price_list_id) {
            if(object.price_list) {
              if(object.price_list.list) {              
                this.crud.form.price_list = object.price_list.list
                this.crud.form.price_list_id = object.price_list.list.id
              }
            }          
          }    
          
          this.getBalanceCurrentAccount()
        } else {
          this.crud.form.customers = null
          this.crud.form.customers_id = 0

          this.balanceCurrentAccount = 0
        }
      }, 
      loadPointsSales (object) {                
        if(object){
          this.crud.form.points_sales = object
          this.crud.form.points_sales_id = object.id       
          
          this.getBalanceCurrentAccount()
        } else {
          this.crud.form.points_sales = null
          this.crud.form.points_sales_id = 0

          this.getBalanceCurrentAccount()
        }
      },      
      loadMethodsPayment (object) {
        if(object){
          this.crud.form.methods_payment = object
          this.crud.form.methods_payment_id = object.id             

          let days = parseInt(object.term)
          this.crud.form.date_expiration = moment(this.crud.form.date).add(days,'d').format('YYYY-MM-DD')                                    
        } else {
          this.crud.form.methods_payment = null
          this.crud.form.methods_payment_id = 0
        }
      },
      loadMethodsAcopio (object) {
        if(object){
          this.crud.form.methods_acopio = object
          this.crud.form.methods_acopio_id = object.id             
        } else {
          this.crud.form.methods_acopio = null
          this.crud.form.methods_acopio_id = 0
        }
      },        
      loadPriceList (object) {
        if(object){
          this.crud.form.price_list = object
          this.crud.form.price_list_id = object.id             
        } else {
          this.crud.form.price_list = null
          this.crud.form.price_list_id = 0
        }
      },        
      loadConcepts(object) {
        if(object){
          this.crud.form.concepts = object
          this.crud.form.concepts_id = object.id             
        } else {
          this.crud.form.concepts = null
          this.crud.form.concepts_id = 0
        }
      },
      prepareExport(data) {        
        this.arr.export = []
        data.forEach(element => {
          var customersID = 0
          var customersCode = ""
          var customersName = ""        
          var methodsPaymentID = 0
          var methodsPaymentName = ""
          var voucherReference = ""
          var currencyID = 0
          var currencyName = ""          
          var methodsAcopioID = 0
          var methodsAcopioName = ""

          if(element.customer) {
            customersID = element.customer.id
            customersCode = element.customer.code
            customersName = element.customer.name            
          }
          if(element.method_payment) {
            methodsPaymentID = element.method_payment.id
            methodsPaymentName = element.method_payment.name
          }
          if(element.currency) {
            currencyID = element.currency.id
            currencyName = element.currency.name
          }          
          if(element.method_acopio) {
            methodsAcopioID = element.method_acopio.id
            methodsAcopioName = element.method_acopio.name
          }            
          if(element.type_voucher) {            
            voucherReference = element.type_voucher.name + ' ' + element.point_sale.point_sale.toString().padStart(4,'0') + '-' + element.number.toString().padStart(8,'0')
          }          

          this.arr.export.push({
            id: element.id,
            date: element.date,                      
            date_expiration: element.date_expiration,                      
            voucherReference: voucherReference,              
            customersID: customersID,
            customersCode: customersCode,
            customersName: customersName,
            methodsPaymentID: methodsPaymentID,
            methodsPaymentName: methodsPaymentName,
            methodsAcopioID: methodsAcopioID,
            methodsAcopioName: methodsAcopioName,                        
            currencyID: currencyID,
            currencyName: currencyName,
            percentage: element.percentage_discount,
            discount: element.amount_discount,
            iva: element.amount_iva,            
            total: element.amount_total,            
            observations: element.observations,
            productID: 0,
            productCode: "",
            productName: "",
            serviceID: 0,
            serviceCode: "",
            serviceName: "",
            conceptID: 0,
            conceptName: "",   
            productCompoundID: 0,
            productCompoundName: "",   
            priceListID: 0,
            priceListName: "",                                                    
            quantity: 0,
            unitMeasurementID: 0,
            unitMeasurementName: "",            
            unit_price: 0,
            amount_net: 0,
            amount_discount: 0,
            amount_iva: 0,                          
            amount_total: 0,
          })

          element.details_trashed.forEach(element1 => {
            var productID = 0
            var productCode = ""
            var productName = ""
            var serviceID = 0
            var serviceCode = ""
            var serviceName = ""            
            var conceptID = 0
            var conceptName = ""     
            var productCompoundID = 0
            var productCompoundName = ""     
            var unitMeasurementID = 0
            var unitMeasurementName = ""               
            var priceListID = 0
            var priceListName = ""   

            if(element1.products_id) {
              productID = element1.product.id
              productCode = element1.product.code
              productName = element1.product.name            
            }

            if(element1.products_colors_id) {
              productID = element1.products_color.id
              productCode = element1.products_color.code
              productName = element1.products_color.name + " (" + element1.products_color.color.name + ")"
            }            

            if(element1.products_waist_id) {
              productID = element1.products_waist.id
              productCode = element1.products_waist.code
              productName = element1.products_waist.name + " (" + element1.products_waist.waist.name + ")"
            }            

            if(element1.products_color_waist_id) {
              productID = element1.products_color_waist.id
              productCode = element1.products_color_waist.code
              productName = element1.products_color_waist.name + " (" + element1.products_color_waist.color.name + " - " + element1.products_color_waist.waist.name + ")"
            }       

            if(element1.services_id) {
              serviceID = element1.service.id
              serviceCode = element1.service.code
              serviceName = element1.service.name            
            }

            if(element1.concepts_id) {
              conceptID = element1.concept.id
              conceptName = element1.concept.name            
            }

            if(element1.products_compound_id) {
              productCompoundID = element1.products_compound.id
              productCompoundName = element1.products_compound.name            
            }      

            if(element1.unit_measurement_id) {
              unitMeasurementID = element1.unit_measurement.id
              unitMeasurementName = element1.unit_measurement.name            
            }            

            if(element1.price_list_id) {              
              priceListID = element1.price_list.id
              priceListName = element1.price_list.name            
            } else {
              if(element.price_list_id) {
                priceListID = element.price_list.id
                priceListName = element.price_list.name                          
              }
            }

            this.arr.export.push({
              productID: productID,
              productCode: productCode,
              productName: productName,
              serviceID: serviceID,
              serviceCode: serviceCode,
              serviceName: serviceName,
              conceptID: conceptID,
              conceptName: conceptName,   
              productCompoundID: productCompoundID,
              productCompoundName: productCompoundName,        
              priceListID: priceListID,
              priceListName: priceListName,                                                
              quantity: element1.quantity,
              unitMeasurementID: unitMeasurementID,
              unitMeasurementName: unitMeasurementName,
              unit_price: element1.price_unit,
              amount_net: element1.amount_net,
              amount_discount: element1.amount_discount,
              amount_iva: element1.amount_iva,                          
              amount_total: element1.amount_total,
            })
          })
        });
      },

      // AMOUNT CURRENT ACCOUNT
      getBalanceCurrentAccount() {
        var result = serviceAPI.filtrarCurrentsAccountsBalance({
          customers_id: this.crud.form.customers_id,
          points_sales_id: this.crud.form.points_sales_id, 
        })

        result.then((response) => {
          var data = response.data
          this.balanceCurrentAccount = data
        })
      },

      // ABM MAIN
      add(type = 'factura', destination = 'default') {										
        this.crud.form.id = 0           
        this.crud.form.date = moment().format('YYYY-MM-DD')
        this.crud.form.date_expiration = moment().add(10,'d').format('YYYY-MM-DD')
        this.crud.form.observations = ''
        this.crud.form.staff_id = Helper.getStaffId()
        this.crud.form.sellers_id = 0
        this.crud.form.customers_id = 0
        this.crud.form.customers = null   
        this.crud.form.points_sales_id = 0
        this.crud.form.points_sales = null  
        this.crud.form.methods_payment_id = 0
        this.crud.form.methods_payment = null      
        this.crud.form.price_list_id = 0
        this.crud.form.price_list = null         
        this.crud.form.concepts_id = 0
        this.crud.form.concepts = null
        this.crud.form.percentage_discount = 0                                 
        this.crud.form.detailProducts = []
        this.crud.form.detailProductsCompound = []
        this.crud.form.detailServices = []
        this.crud.form.detailConcepts = []
        this.crud.form.invoiceRelated = []
        this.crud.form.methods_acopio_id = 0
        this.crud.form.methods_acopio = null    
        
        this.balanceCurrentAccount = 0

				if(type=='factura' && destination=='default')
          this.modal.form.title = "Nueva Factura"

				if(type=='notadebito' && destination=='default')
          this.modal.form.title = "Nueva Nota de Débito"

				if(type=='notacredito' && destination=='default')
          this.modal.form.title = "Nueva Nota de Crédito"

				if(type=='notacredito' && destination=='discounts')
          this.modal.discounts.title = "Nueva Nota de Crédito (Descuentos en Facturas)"          

        this.crud.form.selectTypeVoucher = type
        this.crud.form.selectDestination = destination

        if(destination=='default')
          this.modal.form.active = true

        if(destination=='discounts')
          this.modal.discounts.active = true          
      },                     
      save() {
        this.$bvModal.msgBoxConfirm('¿Desea continuar con la generación del comprobante?', {
          title: 'Generar Comprobante',
          size: 'sm',
          buttonSize: 'sm',
          okVariant: 'dark',
          okTitle: 'GENERAR',
          cancelTitle: 'Cancelar',
          footerClass: 'p-2',
          headerBgVariant: 'dark',
          headerTextVariant: 'white',
          hideHeaderClose: false,
          centered: true
        })
        .then(value => {
          if (value) {          
            let loader = this.$loading.show();
            
            var result = serviceAPI.agregarComprobante(this.crud.form);            

            result.then((response) => {

              var result1 = serviceAPI.eliminarComprobante(response.data.id);

              result1.then((response1) => {      
                this.modal.form.active = false
                this.modal.discounts.active = false

                loader.hide()
                this.filterSales()
                this.$awn.success("Comprobante generado con éxito");
              })                          
              .catch(error => {
                loader.hide()
                this.$awn.alert(Error.showError(error));
              })

            })
          }
        })
        .catch(error => {
          this.$awn.alert(Error.showError(error));
        })              
      },          
      cancel() {
        this.$bvModal.msgBoxConfirm('¿Desea cancelar el comprobante?', {
          title: 'Cancelar el Comprobante',
          size: 'sm',
          buttonSize: 'sm',
          okVariant: 'danger',
          okTitle: 'SI',
          cancelTitle: 'NO',
          footerClass: 'p-2',
          headerBgVariant: 'danger',
          headerTextVariant: 'white',
          hideHeaderClose: false,
          centered: true
        })
        .then(value => {
          if (value) {        
            this.modal.form.active = false
            this.modal.discounts.active = false

            // blanqueo invoice from
            this.crud.form.remits_id = 0
            this.crud.form.remits = null
          }
        })
        .catch(error => {
          this.$awn.alert(Error.showError(error));
        })   
      },              
      setDetailItems(event, type) { 
        if(type=="products") {
          this.crud.form.detailProducts = event                
        } 
        if(type=="productsCompound") {          
          this.crud.form.detailProductsCompound = event                
        }               
        if(type=="services") {
          this.crud.form.detailServices = event                
        } 
        if(type=="concepts") {
          this.crud.form.detailConcepts = event                
        }                 
      },      

      // CALC
      getPendingVoucherSale(item) {
        return parseFloat(item.amount_total) - parseFloat(item.amount_impute)
      },

      // VALID
      saleValidCrudGeneral() {        
        if(this.crud.form.points_sales && !this.crud.form.points_sales.nd) {
          this.$awn.alert("No puede realizar una factura provisoria relacionada con AFIP");
          return false
        }

        if(this.crud.form.customers_id && this.crud.form.points_sales_id  && this.crud.form.methods_payment_id) {
          return true;
        } else {
          if(!this.crud.form.customers_id) {
            this.$awn.alert("No se cargó el cliente");
            return false;
          }
          if(!this.crud.form.points_sales_id) {
            this.$awn.alert("No se cargó el punto de venta");
            return false;
          }                        
          if(!this.crud.form.methods_payment_id) {
            this.$awn.alert("No se cargó el método de pago");
            return false;
          }
        }        
      },
      saleValidCrudDetail() {
        if( this.crud.form.detailProducts.length || 
            this.crud.form.detailProductsCompound.length || 
            this.crud.form.detailServices.length || 
            this.crud.form.detailConcepts.length) {
          this.getContabilidad()
          return true;
        } else {
          this.$awn.alert("No se cargó ningún item");
          return false;
        }
      },
      saleValidCrudRelation() {
        var sum = 0
        if(this.crud.form.invoiceRelated.length) {
          this.crud.form.invoiceRelated.forEach(element => {
            sum = parseFloat(sum) + parseFloat(element.amount)
          });

          if(parseFloat(sum.toFixed(2)) != parseFloat(this.totals.total.toFixed(2))) {
            this.$awn.alert("Falta imputar comprobantes");
            return false;                      
          } else {
            return true          
          }
        } else {
          this.$awn.alert("Es obligatorio vincular una o mas factura a una nota de crédito o débito");
          return false;          
        }
      },      
      salesValidCrudAccounting() {
        if(this.crud.form.accountingEntry) {
          return true
        } else {
          this.$awn.alert("Revisar el Asiento Contable");
          return false
        }
      },  
      
      // VALID DESC (NC)
      saleValidCrudGeneralDiscounts() {  
        if(this.crud.form.points_sales && !this.crud.form.points_sales.nd) {
          this.$awn.alert("No puede realizar una factura provisoria relacionada con AFIP");
          return false
        }

        if(this.crud.form.customers_id && this.crud.form.points_sales_id && this.crud.form.concepts_id) {
          return true;
        } else {
          if(!this.crud.form.customers_id) {
            this.$awn.alert("No se cargó el cliente");
            return false;
          }
          if(!this.crud.form.points_sales_id) {
            this.$awn.alert("No se cargó el punto de venta");
            return false;
          }                                
          if(!this.crud.form.concepts_id) {
            this.$awn.alert("No se cargó el concepto");
            return false;
          }                                          
        }        
      },      
      saleValidCrudImputationDiscounts() {
        if(this.calc.discounts>0) {
          this.setVouchersPendingDiscounts()
          this.getContabilidad()
          return true; 
        } else {
          this.$awn.alert("No puede continuar sin aplicar descuentos");
          return false; 
        }        
      },

      // INVOICE RELACIONADA EN NC (DISCOUNTS)
      obtenerVouchersPendingImputarByCustomers() {
        this.crud.formPending = []
        
        if(this.crud.form.customers_id && this.crud.form.points_sales_id) {
          var result = serviceAPI.filtrarPendienteImputar({
            customers_id:this.crud.form.customers_id, 
            points_sales_id: this.crud.form.points_sales_id,
          })        
          result.then((response) => {
            var data = response.data    
            
            this.arr.pending = data   
            this.arr.pending.forEach(element => {                        
              this.crud.formPending.push({              
                check: false,
                id: element.id,                            
                discounts: 0,
                amount: 0,
                amount_total: 0,
                amountPending: parseFloat(element.amount_total) - parseFloat(element.amount_impute),
                amountNet: parseFloat(element.amount_net),
                maxAmount: parseFloat(element.amount_total) - parseFloat(element.amount_impute),   
                operation: element.type_voucher.type_balance,
                voucher: element,
              })
            });
          })                   
        }
      },   
      calcVoucherPending(item) {
        return parseFloat(item.amount_total) - parseFloat(item.amount_impute)
      },
      validCheck(index) {                     
        if(this.crud.formPending[index].check) {                    
          this.crud.formPending[index].discounts = 0
          this.crud.formPending[index].amount = 0
          this.crud.formPending[index].amount_total = 0
          this.forceUpdateItem()
        }

        this.getAmountDiscounts()
      },
      validAmount(index){
        if(this.crud.formPending[index].discounts=='') {
          this.crud.formPending[index].discounts = 0
          this.crud.formPending[index].amount = 0
          this.crud.formPending[index].amount_total = 0          
          this.crud.formPending[index].check = false
        }

        var aliquot = 0
        if(this.crud.form.concepts && this.crud.form.concepts.iva_conditions) {
          aliquot = this.crud.form.concepts.iva_conditions.aliquot
        }

        var calcDiscounts = (parseFloat(this.crud.formPending[index].amountNet) * parseFloat(this.crud.formPending[index].discounts)) / 100             
        var calcDiscountsTotal = calcDiscounts + ((calcDiscounts * aliquot) / 100)

        this.crud.formPending[index].amount = parseFloat(calcDiscounts).toFixed(2)
        this.crud.formPending[index].amount_total = parseFloat(calcDiscountsTotal).toFixed(2)
        this.forceUpdateItem()

        if( parseFloat(calcDiscountsTotal) > parseFloat(this.crud.formPending[index].maxAmount)) {          
          this.crud.formPending[index].discounts = 0
          this.crud.formPending[index].amount = 0
          this.crud.formPending[index].amount_total = 0
          this.crud.formPending[index].check = false
          this.$awn.alert("El descuento no puede ser mayor al total pendiente de imputación");
          this.forceUpdateItem()
        }

        if( parseFloat(this.crud.formPending[index].amount_total) < 0) {          
          this.crud.formPending[index].discounts = 0
          this.crud.formPending[index].amount = 0
          this.crud.formPending[index].amount_total = 0
          this.crud.formPending[index].check = false
          this.$awn.alert("No es posible un descuento menor a cero");
          this.forceUpdateItem()
        }   
        
        if( parseFloat(this.crud.formPending[index].amount_total) == 0) {         
          this.crud.formPending[index].discounts = 0 
          this.crud.formPending[index].amount = 0
          this.crud.formPending[index].amount_total = 0
          this.crud.formPending[index].check = false
          this.forceUpdateItem()
        }           
        
        this.getAmountDiscounts()
      },  
      getAmountDiscounts() {        
        var discounts = 0
        if(this.crud.formPending) {          
          this.crud.formPending.forEach(element => {             
            if(element.check) {    
              if(element.operation == '+') {
                discounts = parseFloat(discounts) + parseFloat(element.amount_total)
              } else {
                discounts = parseFloat(discounts) - parseFloat(element.amount_total)
              }
            }
          });   
        }
        this.calc.discounts = discounts    
      },    
      setVouchersPendingDiscounts() {
        // cargo el concepto del descuento
        this.crud.form.detailConcepts = []        
        this.crud.form.detailConcepts.push({
          id: this.crud.form.concepts.id,
          concepts: this.crud.form.concepts,           
          quantity: 1, 
          price: parseFloat(this.calc.discounts), 
          description: this.crud.form.concepts.name,                      
        })
          
        var subtotal = 0
        var total = 0
        this.crud.form.invoiceRelated = []
        this.aux.detailConcepts = []
        if(this.crud.formPending) {                    
          this.crud.formPending.forEach(element => {   
            if(element.check && parseFloat(element.amount_total)>0) {
              // cargo las facturas relacionadas con la nota de credito
              this.crud.form.invoiceRelated.push({        
                voucher: element.voucher,
                reference: element.voucher.type_voucher.name + ' ' + element.voucher.point_sale.point_sale.toString().padStart(4,'0') + '-' + element.voucher.number.toString().padStart(8,'0'),
                amount: element.amount_total,
              }) 

              // cargo el auxilar del concepto para los asientos contables
              this.aux.detailConcepts.push({          
                quantity: 1, 
                price: parseFloat(element.amount), 
                iva: parseFloat(element.amount_total) - parseFloat(element.amount),  
                concepts: this.crud.form.concepts,                   
              })   

              // totalizadores
              subtotal = subtotal + parseFloat(element.amount)
              total = total + parseFloat(element.amount_total)
            }
          })
        }
        
        // cargo los totales
        this.totals.subtotal = parseFloat(subtotal)
        this.totals.discount = 0
        this.totals.iva = parseFloat(total) - parseFloat(subtotal)
        this.totals.total = parseFloat(total)
        
        // cargo el iva
        this.totals.arrIva = []
        this.totals.arrIva[this.crud.form.concepts.iva_conditions_id] = this.totals.iva
      },
      forceUpdateItem() {
        this.itemForceUpdate = this.itemForceUpdate + 1
      },      

      // SELECT VOUCHER 
      searchVoucherByCustomer() {
        if(!this.crud.form.id) {
          if(this.crud.form.customers_id && this.crud.form.points_sales_id ) {
            var result = serviceAPI.obtenerVoucherByCustomer({
              'customers_id': this.crud.form.customers_id,
							'reference': this.crud.form.selectTypeVoucher,
            });
            result.then((response) => {    
							this.crud.form.types_vouchers_id = response.data[0].id							
							this.searchVoucher()                          
            })
						.catch(error => {
							this.$awn.alert(Error.showError(error));
						})   						           
          }
        } else {                                      
          if( this.crud.form.types_vouchers ) {    
            // simple                      
            this.modal.form.title = this.crud.form.types_vouchers.name
            this.modal.form.title = this.modal.form.title + ' ' + this.crud.form.points_sales.point_sale.toString().padStart(4,'0')
            this.modal.form.title = this.modal.form.title + '-' + this.crud.form.number.toString().padStart(8,'0')

            // nc discounts
            this.modal.discounts.title = this.crud.form.types_vouchers.name
            this.modal.discounts.title = this.modal.discounts.title + ' ' + this.crud.form.points_sales.point_sale.toString().padStart(4,'0')
            this.modal.discounts.title = this.modal.discounts.title + '-' + this.crud.form.number.toString().padStart(8,'0')            
          }                   
        }
      },			
      searchVoucher() {
        if(!this.crud.form.id) {
          if(this.crud.form.customers_id && this.crud.form.points_sales_id ) {
            var result = serviceAPI.obtenerVoucherById({
              'points_sales_id': this.crud.form.points_sales_id,
              'types_vouchers_id' : this.crud.form.types_vouchers_id,
            });
            result.then((response) => {                      
              this.crud.form.types_vouchers = response.data.type_voucher
              this.crud.form.letter = response.data.type_voucher.letter                    
              this.crud.form.number = response.data.number
              
              if( this.crud.form.types_vouchers ) {              
                // simple
                this.modal.form.title = this.crud.form.types_vouchers.name
                this.modal.form.title = this.modal.form.title + ' ' + this.crud.form.points_sales.point_sale.toString().padStart(4,'0')
                this.modal.form.title = this.modal.form.title + '-' + this.crud.form.number.toString().padStart(8,'0')

                // nc discounts
                this.modal.discounts.title = this.crud.form.types_vouchers.name
                this.modal.discounts.title = this.modal.discounts.title + ' ' + this.crud.form.points_sales.point_sale.toString().padStart(4,'0')
                this.modal.discounts.title = this.modal.discounts.title + '-' + this.crud.form.number.toString().padStart(8,'0')                
              }
                          
            })           
          }
        } else {                                      
          if( this.crud.form.types_vouchers ) {                          
            this.modal.form.title = this.crud.form.types_vouchers.name          
            this.modal.form.title = this.modal.form.title + ' ' + this.crud.form.points_sales.point_sale.toString().padStart(4,'0')
            this.modal.form.title = this.modal.form.title + '-' + this.crud.form.number.toString().padStart(8,'0')

            this.modal.discounts.title = this.crud.form.types_vouchers.name          
            this.modal.discounts.title = this.modal.discounts.title + ' ' + this.crud.form.points_sales.point_sale.toString().padStart(4,'0')
            this.modal.discounts.title = this.modal.discounts.title + '-' + this.crud.form.number.toString().padStart(8,'0')            
          }                   
        }
      },
      searchTotalsTemporary() {
        if(this.crud.form.detailProducts || this.crud.form.detailProductsCompound || this.crud.form.detailServices || this.crud.form.detailConcepts) {
          var result = serviceAPI.obtenerVoucherTotalsTemporary({
            'header': this.crud.form,
            'detailsProducts': this.crud.form.detailProducts,
            'detailsProductsCompound': this.crud.form.detailProductsCompound,   
            'detailsServices': this.crud.form.detailServices,
            'detailsConcepts': this.crud.form.detailConcepts,
          });
          result.then((response) => {             
            var data = response.data   
            
            this.aux.detailProducts = data.detailProducts
            this.aux.detailProductsCompound = data.detailProductsCompound
            this.aux.detailServices = data.detailServices
            this.aux.detailConcepts = data.detailConcepts

            this.totals.subtotal = data.amount_net
            this.totals.discount = data.amount_discount
            this.totals.subtotal_discount = data.amount_subtotal
            this.totals.iva = data.amount_iva
            this.totals.total = data.amount_total
            this.totals.arrIva = data.arrIva
          })           
        }                   
      },
      validDiscounts() {
        if(this.crud.form.percentage_discount=='') {          
          this.crud.form.percentage_discount = 0
        } else {          
          if( parseFloat(this.crud.form.percentage_discount) < 0 || 
              parseFloat(this.crud.form.percentage_discount) > 100 ) {            
            this.crud.form.percentage_discount = 0
          } 
        }       
        this.searchTotalsTemporary()
      },      

      // INVOICE RELACIONADA EN NC
      getInvoiceRelated(event) {
        this.crud.form.invoiceRelated = event
      },       

      // FILTER
      filterLoad() {
        this.filterLoadCustomers()          
        this.filterLoadSellers()   
        this.filterLoadPointSale()   
        this.filters.date_start = moment().subtract(30, 'days').format('YYYY-MM-DD') 
        this.filters.date_end = moment().format('YYYY-MM-DD') 
      },
      filterLoadCustomers() {
        var result = serviceAPI.obtenerClientes()        
        result.then((response) => {
          var data = response.data    

          this.arr.filters.customers = []
          data.forEach(element => {         
            if(element.code) {
              this.arr.filters.customers.push({ code: element.id, label: element.code + ' | ' + element.name })            
            } else {
              this.arr.filters.customers.push({ code: element.id, label: element.name })            
            }                             
          });          
        })   
      },
      filterLoadSellers() {
        var result = serviceAPI.obtenerVendedores()        
        result.then((response) => {
          var data = response.data    

          this.arr.filters.sellers = []
          data.forEach(element => {                            
            this.arr.filters.sellers.push({ code: element.id, label: element.name })            
          });          
        })   
      },
      filterLoadPointSale() {
        var result = serviceAPI.obtenerPuntosVentas()        
        result.then((response) => {
          var data = response.data    

          this.arr.filters.points_sales = []
          data.forEach(element => {                            
            this.arr.filters.points_sales.push({ code: element.id, label: element.name })            
          });          
        })   
      },           
      filterSales(forceOpenSub=false) {
        this.table.isBusy = true
        var result = serviceAPI.filtrarComprobanteTrashed(this.filters, this.table.currentPage)        
        result.then((response) => {          
          var data = response.data
          this.table.tablePaginate = data

          this.table.items = data.data
          this.arr.sales = data.data
          this.prepareExport(data.data)
          
          this.table.items.forEach(element => {            
            if(forceOpenSub) {
              if(element.id == this.itemSelected.id) {
                this.openSub(element)
              }              
            } 

            if((element.amount_total != element.amount_impute) && element.type_voucher.type_balance=='+') {
              element._rowVariant = 'warning'
            }            
          });

          if(this.table.rowSelected!=null) {            
            this.table.items[this.table.rowSelected]._showDetails = true                   
          }         

          if(this.$refs.table) {            
            this.$refs.table.$forceUpdate()
          }          
          
          this.table.isBusy = false
        })           
        .catch(error => {            
          this.table.isBusy = false  
          this.$awn.alert(ErrorToken.valid(error));
        })  
      },    

      // COMPROBANTE DETAIL
      openSub(item) {                
        this.itemSelected = item                       
        
        this.tableSub.items = item.details_trashed           
        this.loadStyleConfig()
        this.loadImputations(item)
      },    
      hideSub() {
        this.tableSub.items=[]
        this.itemSelected = null
      },               
      openDetail() {                      
        this.tableDetail.items = this.itemSelected.details_trashed
        
        if(this.itemSelected.accounting_entries_trashed){
          if(this.itemSelected.accounting_entries_trashed.details_trashed){
            this.tableAccounting.items = this.itemSelected.accounting_entries_trashed.details_trashed
          } else {
            this.tableAccounting.items = []
          }
        } else {
          this.tableAccounting.items = []
        }

        this.modal.detail.title = "Detalle del Comprobante"
        this.modal.detail.active = true
      }, 
      getDetailCode(item) {        
        if(item.products_id || item.products_colors_id || item.products_waist_id || item.products_color_waist_id) {
          return this.getProductCode(item)
        }
        if(item.services_id) {
          return item.service.code
        }        
        if(item.concepts_id) {
          return item.concept.code
        }   
        if(item.products_compound_id) {          
          return item.products_compound.code
        }                       
      },
      getDetailName(item) {
        if(item.products_id || item.products_colors_id || item.products_waist_id || item.products_color_waist_id) {
          return this.getProductName(item)
        }
        if(item.services_id) {
          return item.description
        }        
        if(item.concepts_id) {          
          return item.description
        }   
        if(item.products_compound_id) {          
          return item.description
        }                      
      },      
      getProductCode(item) {
        var code = ''
        var prod = null

        if(item.code) {
          code = item.code
        }          

        if(item.products_id) {
          if(code) {
            return code
          } else {                      
            return item.product.code
          }
        }

        if(item.products_colors_id) {
          prod = item.products_color 
          if(code) {
            return code
          } else {
            return prod.code
          }           
        }

        if(item.products_waist_id) {
          prod = item.products_waist 
          if(code) {
            return code
          } else {
            return prod.code
          }                     
        }

        if(item.products_color_waist_id) {
          prod = item.products_color_waist 
          if(code) {
            return code
          } else {
            return prod.code
          }                     
        }
      },      
      getProductName(item) {        
        var name = ''
        var prod = null

        if(item.description) {
          name = item.description
        }          
        
        if(item.products_id) {
          if(name) {
            return name
          } else {
            return item.product.name
          }          
        }

        if(item.products_colors_id) {
          prod = item.products_color 
          if(name) {
            return name + ' (' + prod.color.name + ')'
          } else {
            return prod.name + ' (' + prod.color.name + ')'
          }                    
        }

        if(item.products_waist_id) {
          prod = item.products_waist           
          if(name) {
            return name + ' (' + prod.waist.name + ')'
          } else {
            return prod.name + ' (' + prod.waist.name + ')'
          }
        }

        if(item.products_color_waist_id) {
          prod = item.products_color_waist 
          if(name) {
            return name + ' (' + prod.color.name + ' - ' + prod.waist.name + ')'
          } else {
            return prod.name + ' (' + prod.color.name + ' - ' + prod.waist.name + ')'
          }          
        }
      },  
      // IMPUTATIONS
      loadImputations(item) {        
        var arrImputations = []        
               
        if(item.sales_related_trashed) {          
          item.sales_related_trashed.forEach(element => {              
            arrImputations.push({
              date: element.sale_related.date,
              reference: element.sale_related.type_voucher.name + ' ' + element.sale_related.point_sale.point_sale.toString().padStart(4,'0') + '-' + element.sale_related.number.toString().padStart(8,'0'),
              amount_total: element.amount_total
            })
          });
        }                 

        this.tableImputation.items = arrImputations
      },      

      // ACTION
      approvalSales(item) {
        this.$bvModal.msgBoxConfirm('¿Desea aprobar el Comprobante?', {
          title: 'Aprobación de Comprobante',
          size: 'sm',
          buttonSize: 'sm',
          okVariant: 'success',
          okTitle: 'APROBAR',
          cancelTitle: 'Cancelar',
          footerClass: 'p-2',
          headerBgVariant: 'success',
          headerTextVariant: 'white',
          hideHeaderClose: false,
          centered: true
        })
        .then(value => {
          if (value) {     
            let loader = this.$loading.show();
              
            var result = serviceAPI.aprobarComprobanteTrashed({
              id: item.id
            });

            result.then((response) => {                            
              this.modal.form.active = false        
              loader.hide()
              this.filterSales()
              this.$awn.success("Comprobante Aprobado");              
            })
            .catch(error => {
              loader.hide()
              this.$awn.alert(Error.showError(error));
            }) 
          }
        })
        .catch(error => {
          this.$awn.alert(Error.showError(error));
        })             
      },
      disapproveSales(item) {        
        this.$bvModal.msgBoxConfirm('¿Desea desaprobar el Comprobante?', {
          title: 'Desaprobación de Comprobante',
          size: 'sm',
          buttonSize: 'sm',
          okVariant: 'danger',
          okTitle: 'GENERAR',
          cancelTitle: 'Cancelar',
          footerClass: 'p-2',
          headerBgVariant: 'danger',
          headerTextVariant: 'white',
          hideHeaderClose: false,
          centered: true
        })
        .then(value => {          
          if (value) {         
            let loader = this.$loading.show();
              
            var result = serviceAPI.desaprobarComprobanteTrashed({
              id: item.id
            });

            result.then((response) => {                            
              this.modal.form.active = false        
              loader.hide()
              this.filterSales()
              this.$awn.success("Comprobante NO Aprobado");              
            })
            .catch(error => {
              loader.hide()
              this.$awn.alert(Error.showError(error));
            })                 
          }
        })
        .catch(error => {
          this.$awn.alert(Error.showError(error));
        })   
      },

      /****************
       * CONTABILIDAD *
       * **************/      
      getContabilidad() {          
        this.contabilidad.render = false
        this.contabilidad.typeDebe = ""
        this.contabilidad.typeHaber = "",
        this.contabilidad.defaultDebe = []
        this.contabilidad.defaultHaber = []
        this.contabilidad.reference = this.getRefVoucher
        this.contabilidad.amountTotal = parseFloat(this.totals.total)
        
        // ASIENTO POR DEFECTO 
        if( this.crud.form.selectTypeVoucher == 'factura' || 
            this.crud.form.selectTypeVoucher == 'notadebito') {              
          this.contabilidad.defaultDebe = this.getContabilidadAsientoVenta('debe')
          this.contabilidad.defaultHaber = this.getContabilidadAsientoVenta('haber')
        } 
        if(this.crud.form.selectTypeVoucher == 'notacredito') {
          this.contabilidad.defaultDebe = this.getContabilidadAsientoCredito('debe')
          this.contabilidad.defaultHaber = this.getContabilidadAsientoCredito('haber')          
        }
        // END
        
        this.contabilidad.render = true
        this.forceUpdate()
      },

      getAsiento(object) {
        this.crud.form.accountingEntry = object
      },
      forceUpdate() {
        this.itemAccountingForceUpdate = this.itemAccountingForceUpdate + 1
      },

      getIvaCondition() {
        var result = serviceAPI.obtenerCondicionesIva()        
        result.then((response) => {
          var data = response.data    
          this.arr.ivaCondition = data          
        })   
      },  

      getContabilidadAsientoVenta(type) {                     
        if(type == 'debe') {        
          
          var arrDebe = []

          var totalConcepts = 0
          if(this.aux.detailConcepts) {
            this.aux.detailConcepts.forEach(element => {     
              totalConcepts = totalConcepts + parseFloat(element.price) + parseFloat(element.iva)

              arrDebe.push(
                {
                  account: element.concepts.accounting_account_default,
                  amount: parseFloat(element.price) + parseFloat(element.iva),
                  disabled: true,
                }
              )
            });
          }

          if( parseFloat(this.totals.total) > totalConcepts ) {
            arrDebe.push(
              {
                account: this.crud.form.customers.accounting_account_default,
                amount: parseFloat(this.totals.total) - totalConcepts,
                disabled: true,
              }
            )
          }

          return arrDebe
        }        

        if(type == 'haber') {        
          var arrHaber = [
            {
              account: this.crud.form.customers.accounting_account_secondary,
              amount: parseFloat(this.totals.total) - parseFloat(this.totals.iva),
              disabled: true,
            }
          ]

          if(this.totals.arrIva) {
            Object.entries(this.totals.arrIva).forEach(([index1, element1]) => {              
              this.arr.ivaCondition.forEach(element2 => {              
                if(index1 == element2.id) {
                  arrHaber.push({
                    account: element2.accounting_account_default,
                    amount: parseFloat(element1),
                    disabled: true,                  
                  })
                }
              });
            })
            
          }     
          
          return arrHaber
        }        
      },   
      getContabilidadAsientoCredito(type) {               
        if(type == 'debe') {        
          var arrDebe = [
            {
              account: this.crud.form.customers.accounting_account_secondary,
              amount: parseFloat(this.totals.total) - parseFloat(this.totals.iva),
              disabled: true,
            }
          ]

          if(this.totals.arrIva) {
            Object.entries(this.totals.arrIva).forEach(([index1, element1]) => {              
              this.arr.ivaCondition.forEach(element2 => {                
                if(index1 == element2.id) {
                  arrDebe.push({
                    account: element2.accounting_account_default,
                    amount: parseFloat(element1),
                    disabled: true,                  
                  })
                }
              });
            })
            
          }     

          return arrDebe
        }        

        if(type == 'haber') {  

          var arrHaber = []

          var totalConcepts = 0
          if(this.aux.detailConcepts) {
            this.aux.detailConcepts.forEach(element => {     
              totalConcepts = totalConcepts + parseFloat(element.price) + parseFloat(element.iva)

              arrHaber.push(
                {
                  account: element.concepts.accounting_account_default,
                  amount: parseFloat(element.price) + parseFloat(element.iva),
                  disabled: true,
                }
              )
            });
          }

          if( parseFloat(this.totals.total) > totalConcepts ) {
            arrHaber.push(
              {
                account: this.crud.form.customers.accounting_account_default,
                amount: parseFloat(this.totals.total) - totalConcepts,
                disabled: true,
              }
            )
          }

          return arrHaber
        }        
      }           
    }
  }
</script>
<style>
  .crud-sales-title {
    font-size: 15px;
    font-weight: 600;
  }
  .crud-sales-code {
    color: gray;
    font-size: 12px;
  }   
  #details_items {
    position: fixed;
    right: 15px;    
  }     
  .table-sales-sub {
    overflow: auto;
    max-height: 350px;
  }
  .crud-sales-title-table-custom {
    color: #fff;
    background-color: #2f353a;
    border-color: #40484f;
    font-weight: 700;
    padding-right: 10px;
    width: 30%;
    text-align: right
  }
  .crud-sales-value-table-custom {
    padding-left: 5px;
  }  
  .table-full-detail-sales {
    overflow: auto;
    max-height: 350px;    
  }
  .sales-alert-retailer {
    font-weight: bold;
    color: gray;
    font-size: 12px;
  }
  .sales-alert-noretailer {
    font-weight: bold;
    color: darkblue;
    font-size: 12px;
  } 
  .sale-total-table-paid {
    color: darkgreen;
    font-weight: 700;
  } 
  .sale-total-table-nopaid {
    color: darkred;
    font-weight: 700;
  }
  .crud-sales-nro-internal {
    color: var(--mdc-theme-primary);
    font-size: 12px;
  }
  .crud-new-sales-button-add {
    margin-top: 29px;
  }
  .crud-sales-totales-items-header {
    background: var(--dark);
    color: #fff;
    font-size: 12px;
    padding-right: 10px;
    padding-top: 2px;
    padding-bottom: 2px;    
    text-align: right;
    font-weight: 700;
  }
  .crud-sales-counts-items {
    font-size: 12px;    
    text-align: right;
    display: flex;
    justify-content: center;
    align-content: center;
    flex-direction: column;    
    border-color: var(--dark);
    border-style: solid;
    border-width: 1px;    
  }  
  .crud-sales-totales-items {
    font-size: 12px;    
    text-align: right;
    display: flex;
    justify-content: center;
    align-content: center;
    flex-direction: column;    
    border-color: var(--dark);
    border-style: dotted;
    border-width: 1px;    
  }  
  .crud-sales-totales-items-amount {
    font-size: 15px;    
    text-align: right;
    display: flex;
    justify-content: center;
    align-content: center;
    flex-direction: column;    
    border-color: var(--dark);
    border-style: dotted;    
    border-width: 1px;    
  }    
  .crud-sales-wizard .wizard-header {
    padding: 0px;
  }  
  .crud-sales-item-input {
    height: 25px;
    text-align: center;
    font-weight: bold;
    font-size: 16px;
  }  
  .crud-sales-add-total-final {
    font-weight: 800 !important;
    font-size: 15px !important;    
    text-align: right;
  }  
  .crud-sales-table-items {
    height: 400px;    
    overflow: auto;
  }  
  .crud_sales_alert_from {
    padding: 5px 10px;
    margin-bottom: 0px;  
  }
  .crud-sales-totales-discounts-items {
    padding-top: 5px;
  }

  #view_list_sales .card-body {
    padding: 5px;
  }  
</style>